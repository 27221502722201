import _ from 'lodash';
import { toast } from 'react-toastify';
import firebase from '../../firebase';
import { showBackdropWithSpinner, toggleIsNetworkBusy } from './ui';
import { content } from '../../services/content/api';
import { ACTION_TYPE_NAMES } from '../../constants';

export const LOAD_BUSINESS_PLAN = 'LOAD_BUSINESS_PLAN';
export const SET_CUSTOMER_ID = 'SET_CUSTOMER_ID';
export const SET_CUSTOMER_NAME = 'SET_CUSTOMER_NAME';
export const SET_NUM_COLLABS = 'SET_NUM_COLLABS';
export const SET_PREV_SALES_PERIOD = 'SET_PREV_SALES_PERIOD';
export const SET_PREV_SERVICE_SALES = 'SET_PREV_SERVICE_SALES';
export const SET_IS_COLORING_ACTIVE = 'SET_IS_COLORING_ACTIVE';
export const SET_PREV_COLORING_SALES = 'SET_PREV_COLORING_SALES';
export const SET_PREV_RETAIL_SALES = 'SET_PREV_RETAIL_SALES';
export const SET_NEXT_SALES_PERIOD = 'SET_NEXT_SALES_PERIOD';
export const SET_BUSINESS_MODEL_ROWS = 'SET_BUSINESS_MODEL_ROWS';
export const TOGGLE_SUPPORTING_ACTION = 'TOGGLE_SUPPORTING_ACTION';
export const CHANGE_ANIMATION_GOODIE_QTY = 'CHANGE_ANIMATION_GOODIE_QTY';
export const RESET_BUSINESS_PLAN = 'RESET_BUSINESS_PLAN';
export const SET_QUALITATIVE_OBJECTIVES = 'SET_QUALITATIVE_OBJECTIVES';

export function resetBusinessPlan() {
  console.log('resetBusinessPlan called');
  return { type: RESET_BUSINESS_PLAN };
}

export function loadBusinessPlan(planData) {
  return { type: LOAD_BUSINESS_PLAN, payload: planData };
}

export function fetchAndLoadBusinessPlan(planId) {
  return async function(dispatch, getState) {
    try {
      dispatch(showBackdropWithSpinner(true));
      const planData = (await firebase.getPlan(planId)).data();

      const { supportingActionFilters: filters } = getState().ui;
      let cmsSupportingActions = await content.getSupportingActionsWithFilters(
        filters,
        true,
      );

      const planActionsIds = Object.keys(planData.supportingActions);
      const cmsActionsIds = Object.keys(cmsSupportingActions);

      const missingIds = _.difference(planActionsIds, cmsActionsIds);
      if (missingIds.length > 0) {
        const pastActions = await content.getSupportingActionsByIds(missingIds);
        cmsSupportingActions = { ...cmsSupportingActions, ...pastActions };
      }

      const reconciledActionsMap = reconcileSupportingActionState(
        planData.supportingActions,
        cmsSupportingActions,
      );

      const planState = {
        planId,
        ..._.pick(planData, [
          'customerId',
          'customerName',
          'servicePotential',
          'retailPotential',
          'coloringPotential',
          'isColoringActive',
          'prevSalesPeriod',
          'numCollabs',
          'prevServiceSales',
          'prevRetailSales',
          'prevColoringSales',
          'nextSalesPeriod',
          'qualitativeObjectives',
        ]),
        salesTargets: planData.targets,
        supportingActions: reconciledActionsMap,
      };
      dispatch(loadBusinessPlan(planState));
    } catch (err) {
      console.error('fetchAndLoadBusinessPlan error: ', err);
      toast.error(
        'Se ha producido un error al cargar los datos de plan. Por favor inténtalo de nuevo',
      );
    } finally {
      dispatch(showBackdropWithSpinner(false));
    }
  };
}

function reconcileSupportingActionState(
  planSupportingActions,
  cmsSupportingActions,
) {
  const reconciled = _.cloneDeep(cmsSupportingActions);

  for (let actionId in planSupportingActions) {
    const reconciledAction = reconciled[actionId];

    if (reconciledAction) {
      reconciledAction.isSelected = true;
      if (reconciledAction.actionType === ACTION_TYPE_NAMES.animation) {
        const planGoodies = planSupportingActions[actionId].goodies;
        const cmsGoodies = reconciledAction.goodies;
        reconciledAction.goodies = reconcileGoodies(planGoodies, cmsGoodies);
      }
    }
  }

  return reconciled;
}

function reconcileGoodies(planGoodies, cmsGoodies) {
  const reconciled = _.cloneDeep(cmsGoodies);

  for (let goodieId in planGoodies) {
    const reconciledGoodie = reconciled[goodieId];
    if (reconciledGoodie) {
      reconciledGoodie.quantity = planGoodies[goodieId].quantity;
    }
  }

  return reconciled;
}

export function saveBusinessPlan(
  history,
  businessPlanData,
  shouldCommit,
  shouldForward,
) {
  return async function(dispatch) {
    try {
      dispatch(toggleIsNetworkBusy(true));
      console.log('saveBusinessPlan businessPlanData: ', businessPlanData);
      const planId = await firebase.savePlan(businessPlanData, shouldCommit);
      toast.success('Guardado con éxito');
      dispatch(toggleIsNetworkBusy(false));

      if (shouldForward) {
        history.push('/history');
      } else {
        return planId;
      }
    } catch (err) {
      dispatch(toggleIsNetworkBusy(false));
      toast.error(err.message, { autoClose: false });
    }
  };
}

export function shareBusinessPlan(businessPlanData) {
  return async function(dispatch) {
    const id = await dispatch(
      saveBusinessPlan(undefined, businessPlanData, false, false),
    );
    if (id) {
      // TODO: Move URL to constants
      const msg = `Aquí tienes tu link de plan de L'Oréal Productos Profesionales Boost Your Business: https://${process.env.REACT_APP_PUBLIC_URL}/plan/shared/${id}`;
      const win = window.open(`https://web.whatsapp.com/send?text=${msg}`);
      if (win !== null) {
        win.focus();
      }
    }
  };
}

export function setCustomerId(id) {
  return { type: SET_CUSTOMER_ID, payload: id };
}

export function setCustomerName(name) {
  return { type: SET_CUSTOMER_NAME, payload: name };
}

export function setNumCollabs(payload) {
  return { type: SET_NUM_COLLABS, payload };
}

export function setPrevSalesPeriod(payload) {
  return { type: SET_PREV_SALES_PERIOD, payload };
}

export function setPrevRetailSales(payload) {
  return { type: SET_PREV_RETAIL_SALES, payload };
}

export function setPrevServiceSales(payload) {
  return { type: SET_PREV_SERVICE_SALES, payload };
}

export function setIsColoringActive(payload) {
  return { type: SET_IS_COLORING_ACTIVE, payload };
}

export function setPrevColoringSales(payload) {
  return { type: SET_PREV_COLORING_SALES, payload };
}

export function toggleSupportingAction(id) {
  return { type: TOGGLE_SUPPORTING_ACTION, payload: id };
}

export function changeAnimationGoodieQuantity(actionId, goodieId, quantity) {
  return {
    type: CHANGE_ANIMATION_GOODIE_QTY,
    payload: {
      actionId,
      goodieId,
      quantity,
    },
  };
}

export function handleNextSalesPeriodChange(newSalesPeriod) {
  return { type: SET_NEXT_SALES_PERIOD, payload: newSalesPeriod };
}

export function handleBusinessModelRowsChange(newRows) {
  return { type: SET_BUSINESS_MODEL_ROWS, payload: newRows };
}

export function handleQualitativeObjectivesChange(text) {
  return { type: SET_QUALITATIVE_OBJECTIVES, payload: text };
}
