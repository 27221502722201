import {
  SET_ACTIVE_STEP,
  SET_CUSTOMER_LIST,
  SHOW_BACKDROP_WITH_SPINNER,
  TOGGLE_IS_NETWORK_BUSY,
  TOGGLE_MODAL,
} from '../actions/ui';
import { CHANGE_SUPPORTING_ACTION_FILTERS_UI } from '../actions/ui';
import {
  BRAND_DISPLAY_NAME,
  PRODUCT_TYPES_DISPLAY_NAME,
  SUPPORTING_ACTION_FILTERS_LOCALSTORAGE_KEY,
} from '../../constants';
import { objectsHaveSameDeepKeysAndValueTypes } from '../../utils';

const noColor = {
  [PRODUCT_TYPES_DISPLAY_NAME.retail]: true,
  [PRODUCT_TYPES_DISPLAY_NAME.service]: true,
};

const standard = {
  ...noColor,
  [PRODUCT_TYPES_DISPLAY_NAME.color]: true,
};

export const defaultSupportingActionFilters = {
  [BRAND_DISPLAY_NAME.kerastase]: { ...noColor },
  [BRAND_DISPLAY_NAME.loreal]: { ...standard },
  [BRAND_DISPLAY_NAME.redken]: { ...standard },
  [BRAND_DISPLAY_NAME.shu]: { ...noColor },
  [BRAND_DISPLAY_NAME.commonContents]: { ...standard },
};

/**
 * Gets the initial supporting actions filters
 *
 * If the filters do not exist or they are outdated, sets the default
 * Else it returns the filters from local storage
 *
 * @returns {Object} initial supporting action filters to use
 */
function getInitialFilters() {
  const localFilters = localStorage.getItem(
    SUPPORTING_ACTION_FILTERS_LOCALSTORAGE_KEY,
  );
  if (
    localFilters &&
    objectsHaveSameDeepKeysAndValueTypes(
      JSON.parse(localFilters),
      defaultSupportingActionFilters,
    )
  ) {
    return JSON.parse(localFilters);
  } else {
    return defaultSupportingActionFilters;
  }
}

const defaultUiState = {
  activeStep: 0,
  isModalOpen: false,
  isNetworkBusy: false,
  modalData: undefined,
  customerList: null,
  supportingActionFilters: getInitialFilters(),
  showBackdropWithSpinner: false,
};

export default function ui(state = defaultUiState, action) {
  switch (action.type) {
    case SET_CUSTOMER_LIST:
      return { ...state, customerList: action.payload };

    case SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };

    case TOGGLE_MODAL:
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
        modalData: action.payload,
      };

    case TOGGLE_IS_NETWORK_BUSY:
      // If no parameter is provided, toggle previous state
      if (action.payload === undefined) {
        return { ...state, isNetworkBusy: !state.isNetworkBusy };
      }
      return { ...state, isNetworkBusy: action.payload };

    case CHANGE_SUPPORTING_ACTION_FILTERS_UI:
      const { brand, productType, isActive } = action.payload;

      const filters = { ...state.supportingActionFilters };
      filters[brand][productType] = isActive;

      return { ...state, supportingActionFilters: filters };

    case SHOW_BACKDROP_WITH_SPINNER:
      return { ...state, showBackdropWithSpinner: action.payload };

    default:
      return state;
  }
}
