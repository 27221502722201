import React, { useState } from 'react';
import {
  Grid as DxGrid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { columns as allColumns, mockRows, columnExtensions } from './config';
import DateTypeProvider from './DateTypeProvider';
import ExpirationTypeProvider from './ExpirationTypeProvider';
import ChipTypeProvider from './ChipTypeProvider';
import ActionListTypeProvider from './ActionListTypeProvider';

import { PLAN_META } from '../../constants';
import { makeStyles } from '@material-ui/core/styles';
import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
const {
  state,
  salon,
  activationDate,
  finishDate,
  expiration,
  modifiedAt,
} = PLAN_META;

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    '& th': {
      color: theme.palette.text.secondary,
      padding: 8,
    },
    '& td': {
      padding: 8,
      fontSize: '0.8rem',
    },
  },
}));

const chipColumns = [state];
const dateColumns = [modifiedAt, activationDate, finishDate];
const expirationColumns = [expiration];
const actionColumns = ['actions'];

function TableComponent({ ...restProps }) {
  const classes = useStyles();
  return <Table.Table className={classes.tableRoot} {...restProps} />;
}

export default function PlanHistoryTable(props) {
  const { rows, setRows } = props;

  // The plan history table is used in two contexts
  // (a) to see ALL plans for ALL customers in /history
  // (b) to see plans for a single customer
  // For the latter, the customer name becomes redundant; remove it
  let columns = allColumns;
  if (props.showNameColumn === false) {
    columns = columns.filter((column) => column.name !== salon);
  }

  return (
    <>
      <DxGrid rows={rows} columns={columns}>
        <ActionListTypeProvider
          for={actionColumns}
          rows={rows}
          setRows={setRows}
        />
        <ChipTypeProvider for={chipColumns} />
        <DateTypeProvider for={dateColumns} />
        <ExpirationTypeProvider for={expirationColumns} />
        <SortingState
          defaultSorting={[{ columnName: 'modifiedAt', direction: 'desc' }]}
        />
        <IntegratedSorting />
        <Table
          tableComponent={TableComponent}
          columnExtensions={columnExtensions}
        />
        <TableHeaderRow />
      </DxGrid>
    </>
  );
}
