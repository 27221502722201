import React from 'react';
import { Provider } from 'react-redux';
import store from './state/store';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import theme from './theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Firebase, { FirebaseContext } from './firebase';
import BackdropWithSpinner from './components/common/BackdropWithSpinner';

export default function Root(props) {
  return (
    <Provider store={store}>
      <FirebaseContext.Provider value={Firebase}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer
            position="bottom-left"
            draggable={false}
            style={{ whiteSpace: 'pre-wrap ' }}
          />
          <BackdropWithSpinner />
          {props.children}
        </ThemeProvider>
      </FirebaseContext.Provider>
    </Provider>
  );
}
