import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper/index';
import Modal from '@material-ui/core/Modal/index';
import Typography from '@material-ui/core/Typography/index';
import Button from '@material-ui/core/Button/index';

import Spinner from './Spinner';

const useStyles = makeStyles(theme => ({
  modalPaper: {
    position: 'absolute',
    width: 400,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(3),
  },
  commitButton: {
    marginRight: theme.spacing(1),
  },
}));

export default function CommitModal({
  modalOpen,
  setModalOpen,
  isSaving,
  handleSave,
}) {
  const classes = useStyles();

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Paper className={classes.modalPaper}>
        <Typography variant="body1" paragraph>
          Al comprometer el plan, ya no se podrá editar. ¿Deseas continuar?
        </Typography>
        <Button
          className={classes.commitButton}
          variant="contained"
          color="secondary"
          disabled={isSaving}
          startIcon={isSaving && <Spinner />}
          onClick={() => handleSave(true)}
        >
          Confirmar
        </Button>
        <Button onClick={() => setModalOpen(false)}>Cancelar</Button>
      </Paper>
    </Modal>
  );
}
