import {
  differenceInDays,
  parseISO,
  formatISO,
  subDays,
  addDays,
} from 'date-fns';
import { PLAN_STATES, PLAN_META } from '../../constants';

const {
  id,
  salon,
  state,
  activationDate,
  finishDate,
  expiration,
  modifiedAt,
} = PLAN_META;

export const columns = [
  { title: 'Últ. modif.', name: modifiedAt },
  { title: 'Salón', name: salon },
  { title: 'Estado de plan', name: state },
  { title: 'F. activación', name: activationDate },
  { title: 'F. finalización', name: finishDate },
  {
    title: 'Expiración',
    name: expiration,
    getCellValue: (row) => {
      if (row.state === PLAN_STATES.inProgress) {
        return differenceInDays(Date.now(), parseISO(row.finishDate));
      } else if (row.state === PLAN_STATES.pending) {
        return Number.MAX_SAFE_INTEGER - 1;
      } else {
        return Number.MAX_SAFE_INTEGER;
      }
    },
  },
  { title: 'Acciones', name: 'actions' },
];

export const columnExtensions = [{ columnName: 'actions', width: 210 }];

const now = Date.now();

export const mockRows = [
  {
    id: '134B',
    salon: 'José Martínez',
    state: PLAN_STATES.inProgress,
    activationDate: formatISO(subDays(now, 14)),
    finishDate: formatISO(addDays(now, 33)),
  },
  {
    id: '138C',
    salon: 'Lola Rodríguez',
    state: PLAN_STATES.inProgress,
    activationDate: formatISO(subDays(now, 34)),
    finishDate: formatISO(addDays(now, 12)),
  },
  {
    id: '143D',
    salon: 'Christian Del Valle',
    state: PLAN_STATES.inProgress,
    activationDate: formatISO(subDays(now, 64)),
    finishDate: formatISO(subDays(now, 6)),
  },
  {
    id: '123B',
    salon: 'Juan Magallanes',
    state: PLAN_STATES.pending,
    activationDate: undefined,
    finishDate: undefined,
  },
  {
    id: '165D',
    salon: 'Sandro López',
    state: PLAN_STATES.finalized,
    activationDate: formatISO(subDays(now, 174)),
    finishDate: formatISO(subDays(now, 111)),
  },
];
