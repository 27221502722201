import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { SupportingActionDetailViewSection } from './SupportingActionDetailViewSection';
import { SupportingActionViewHeader } from './SupportingActionViewHeader';
import { GoodieList } from './GoodieList';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    maxWidth: 798,
  },
  mainContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
  },
}));

export const SupportingActionDetailView = props => {
  const { supportingAction, isEditable } = props;

  const classes = useStyles();

  const sections = getOrderedSections(supportingAction, isEditable);

  return (
    <Container className={classes.outerContainer}>
      <Grid
        container
        spacing={4}
        justify="center"
        className={classes.mainContent}
      >
        <SupportingActionViewHeader
          supportingAction={supportingAction}
          width={10}
        />
        {sections.map(section => {
          if (section) {
            const { title, component } = section;
            return (
              <Grid key={title} item xs={12}>
                <SupportingActionDetailViewSection
                  title={title}
                  component={component}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </Container>
  );
};

function getOrderedSections(supportingAction, isEditable) {
  const { id: animationId, goodies, additionalLinks } = supportingAction;
  const { whoIsItFor, howDoesItWork, howDoesItHelp, whyNow } = supportingAction;

  return [
    whoIsItFor && {
      title: '¿Para quién es?',
      component: documentToReactComponents(whoIsItFor, renderOptions),
    },
    howDoesItWork && {
      title: '¿Cómo funciona?',
      component: documentToReactComponents(howDoesItWork, renderOptions),
    },
    goodies && {
      title: 'Apoyos para poner en marcha la animación',
      component: <GoodieList {...{ animationId, goodies, isEditable }} />,
    },
    howDoesItHelp && {
      title: '¿Cómo ayuda a tu salón?',
      component: documentToReactComponents(howDoesItHelp, renderOptions),
    },
    whyNow && {
      title: '¿Por qué ahora?',
      component: documentToReactComponents(whyNow, renderOptions),
    },
    additionalLinks && {
      title: 'Recursos adicionales',
      component: additionalLinks.map(link => (
        <Link
          key={link.title}
          href={link.file}
          target="_blank"
          display="block"
          children={link.title}
        />
      )),
    },
  ];
}

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography variant="body1" paragraph>
        {children}
      </Typography>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri} target="_blank" rel="noopener">
        {children}
      </Link>
    ),
  },
};
