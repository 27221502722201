import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 300,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

export default function PaperHeaderContainer(props) {
  const { title, children, childClassName, isFlat, minHeight } = props;
  const classes = useStyles();
  return (
    <Paper
      className={clsx(classes.paper, childClassName)}
      variant={isFlat ? 'outlined' : 'elevation'}
      style={{ minHeight }}
      square={isFlat}
    >
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
      <Divider className={classes.divider} />
      {children}
    </Paper>
  );
}
