import React, { useContext, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import matchSorter from 'match-sorter';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import { FirebaseContext } from '../firebase';
import { getCustomerList } from '../state/actions/ui';

const useStyles = makeStyles(theme => ({
  searchInput: {
    minWidth: 400,
  },
  search: {
    position: 'relative',
    border: `1px solid rgba(0, 0, 0, 0.04)`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255, 255, 255, 0.35)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.55)',
    },
    color: theme.palette.grey[800],
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem',
    },
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    fontSize: '0.9rem',
    padding: theme.spacing(1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
      '&:focus': {
        width: '360px',
      },
    },
  },
  customerIdLabel: {
    fontFamily: 'monospace',
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    verticalAlign: 'bottom',
  },
}));

function CustomerSearchBox(props) {
  const classes = useStyles();
  const history = useHistory();
  const firebase = useContext(FirebaseContext);

  const authUser = useSelector(state => state.session.authUser);
  const customerList = useSelector(state => state.ui.customerList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authUser && authUser.email) {
      dispatch(getCustomerList(firebase, authUser.email));
    }
  }, [authUser.email]);

  const filterOptions = (options, { inputValue }) => {
    if (inputValue === '') {
      return [];
    }
    return matchSorter(options, inputValue, {
      keys: ['companyName', 'id'],
    }).slice(0, 5);
  };

  function OptionLabel(props) {
    return (
      <>
        <span className={classes.customerIdLabel}>[{props.id}]</span>
        &nbsp;&nbsp;{props.companyName}
      </>
    );
  }

  return (
    <Autocomplete
      id="customer-searchbox"
      options={customerList || []}
      filterOptions={filterOptions}
      getOptionLabel={option => <OptionLabel {...option} />}
      clearOnBlur
      disableClearable
      ListboxProps={{ style: { fontSize: '0.9rem', fontWeight: 300 } }}
      onChange={(event, value, reason) => {
        if (reason === 'select-option') {
          console.log('Autocomplete: onChange value: ', value);
          history.push(`/customer/${value.id}`);
        }
      }}
      renderInput={params => {
        // Integrates the search example seen in the AppBar docs in Autocomplete
        // https://material-ui.com/components/app-bar/#app-bar-with-search-field
        return (
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <Input
              disableUnderline
              placeholder="Buscar cliente"
              inputProps={{
                ...params.inputProps,
                className: classes.inputInput,
              }}
              {...params.InputProps}
            />
          </div>
        );
      }}
      freeSolo
    />
  );
}

function mapStateToProps(state) {
  return {
    customerList: state.ui.customerList,
  };
}

const ConnectedCustomerSearchBox = connect(
  mapStateToProps,
  undefined,
)(CustomerSearchBox);

export default ConnectedCustomerSearchBox;
