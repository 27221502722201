import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PaperHeaderContainer from './PaperHeaderContainer';
import PotentialChartTabContainer from './PotentialChartTabContainer';
import BusinessActionList from './SupportingActions/SupportingActionList';
import SellOutTableContainer from './SellOutTableContainer';
import SubViewMainContentArea from './SubViewMainContentArea';
import QualitativeObjectives from './QualitativeObjectives';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  gridRoot: {
    padding: theme.spacing(0, 3),
  },
}));

function SummaryView({ supportingActions, isEditable = false }) {
  const classes = useStyles();

  let actions = supportingActions;
  if (!supportingActions) {
    actions = {};
  }

  const selectedActions = Object.values(actions).filter(
    action => action.isSelected,
  );

  return (
    <SubViewMainContentArea>
      <Grid container spacing={3} className={classes.gridRoot}>
        <Grid item xs={12} md={4}>
          <PaperHeaderContainer title="Potenciales" isFlat>
            <PotentialChartTabContainer />
          </PaperHeaderContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <PaperHeaderContainer title="Objetivos" isFlat>
            <SellOutTableContainer isAbridged />
            <Box mt={4}>
              <QualitativeObjectives isEditable={isEditable} />
            </Box>
          </PaperHeaderContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <PaperHeaderContainer title="Acciones de negocio" isFlat>
            <BusinessActionList list={selectedActions} isEditable={false} />
          </PaperHeaderContainer>
        </Grid>
      </Grid>
    </SubViewMainContentArea>
  );
}

function mapStateToProps(state) {
  return { supportingActions: state.businessPlan.supportingActions };
}

const ConnectedSummaryView = connect(
  mapStateToProps,
  undefined,
)(SummaryView);

export default ConnectedSummaryView;
