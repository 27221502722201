export function generateColumns(numCollabs, salesPeriod) {
  function calculatePeriodSales(salesWeekCollab) {
    return salesWeekCollab * numCollabs * salesPeriod;
  }

  function calculateIncome(salesWeekCollab, averageSalesPrice) {
    return calculatePeriodSales(salesWeekCollab) * averageSalesPrice;
  }

  function calculateCost(salesWeekCollab, averageUnitCost) {
    return calculatePeriodSales(salesWeekCollab) * averageUnitCost;
  }

  function calculateProfit(
    salesWeekCollab,
    averageSalesPrice,
    averageUnitCost,
  ) {
    return (
      calculateIncome(salesWeekCollab, averageSalesPrice) -
      calculateCost(salesWeekCollab, averageUnitCost)
    );
  }

  return [
    { title: 'Producto', name: 'product' },
    { title: 'Tipo', name: 'type' },
    // TODO: Add validation for numCollabs > 0
    {
      title: 'Venta / Colab / Semana',
      name: 'salesWeekCollab',
    },
    {
      title: 'Ticket Medio (PVPR)',
      name: 'averageSalesPrice',
    },
    {
      title: 'Coste Medio (PCAT)',
      name: 'averageUnitCost',
    },
    {
      title: 'Ventas',
      name: 'sellOut',
      getCellValue: row => {
        const val = calculatePeriodSales(row.salesWeekCollab);
        return isNaN(val) ? 'N/A' : val;
      },
    },
    {
      title: 'Ingresos',
      name: 'income',
      getCellValue: row => {
        const val = calculateIncome(row.salesWeekCollab, row.averageSalesPrice);
        return isNaN(val) ? 'N/A' : val;
      },
    },
    {
      title: 'Coste',
      name: 'totalCost',
      getCellValue: row => {
        const val = calculateCost(row.salesWeekCollab, row.averageUnitCost);
        return isNaN(val) ? 'N/A' : val;
      },
    },
    {
      title: 'Beneficio',
      name: 'profit',
      getCellValue: row => {
        const val = calculateProfit(
          row.salesWeekCollab,
          row.averageSalesPrice,
          row.averageUnitCost,
        );
        return isNaN(val) ? 'N/A' : val;
      },
    },
  ];
}

export const defaultColumnExtensions = [
  {
    columnName: 'product',
    width: 120,
  },
  {
    columnName: 'type',
    wordWrapEnabled: true,
    width: 75,
  },
  {
    columnName: 'salesWeekCollab',
    width: 90,
    align: 'right',
    wordWrapEnabled: true,
  },
  {
    columnName: 'sellOut',
    width: 'auto',
    align: 'right',
    wordWrapEnabled: true,
    editingEnabled: false,
  },
  {
    columnName: 'averageUnitCost',
    width: 'auto',
    align: 'right',
    wordWrapEnabled: true,
  },
  {
    columnName: 'averageSalesPrice',
    width: 'auto',
    align: 'right',
    wordWrapEnabled: true,
  },
  {
    columnName: 'totalCost',
    width: 'auto',
    align: 'right',
    wordwrapEnabled: true,
  },
  {
    columnName: 'income',
    width: 'auto',
    align: 'right',
    wordWrapEnabled: true,
    editingEnabled: false,
  },
  {
    columnName: 'profit',
    width: 'auto',
    align: 'right',
    wordWrapEnabled: true,
    editingEnabled: false,
  },
];
