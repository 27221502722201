import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import headerImage from '../media/hero.jpg';
const lowResImage = headerImage;

const useStyles = makeStyles(theme => ({
  heroContainer: {
    position: 'absolute',
    top: 0,
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    zIndex: -1,
  },
  heroImage: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    width: '100vw',
  },
}));

export default function Hero(props) {
  const classes = useStyles();

  const [heroFirstLoad, setHeroFirstLoad] = useState(false);

  return (
    <div className={classes.heroContainer}>
      <img
        className={classes.heroImage}
        src={heroFirstLoad ? headerImage : lowResImage}
        alt=""
        onLoad={() => {
          if (!heroFirstLoad) {
            setHeroFirstLoad(true);
          }
        }}
      />
      {props.children}
    </div>
  );
}
