import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import { parseISO, isDate, isPast, differenceInDays } from 'date-fns';
import { FirebaseContext } from '../../firebase';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withAuthorization from '../withAuthorization';
import withTopBar from '../withTopBar';
import MetricsCard from './MetricsCard';
import SubViewContainer from '../SubViewContainer';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginBottom: theme.spacing(4),
    '& > *:first-child': {
      backgroundColor: 'rgb(45, 45, 45)',
    },
  },
}));

const Metrics = function(subject) {
  this.subject = subject;
  this.last30 = {};
  this.total = {};
};

function toRowFormat(object) {
  return Object.keys(object).map(key => ({ key, ...object[key] }));
}

Metrics.prototype.createRows = function() {
  this.rows = toRowFormat({ last30: this.last30, total: this.total });
  return this;
};

function getAggregatesForIndividualRep(repObject, globals) {
  const { repEmail, metrics } = repObject;
  const aggregatesForRep = new Metrics(repEmail);

  for (let dateKey in metrics) {
    const date = parseISO(dateKey);

    // Check if date keys are as expected; else warn
    if (isDate(date) && isPast(date)) {
      // Add to total
      _.assignWith(aggregatesForRep.total, metrics[dateKey], _.add);
      _.assignWith(globals.total, metrics[dateKey], _.add);

      // If date is in last 30 days, also add to the relevant count
      if (differenceInDays(new Date(), date) <= 30) {
        _.assignWith(aggregatesForRep.last30, metrics[dateKey], _.add);
        _.assignWith(globals.last30, metrics[dateKey], _.add);
      }
    } else {
      console.warn('Key in metrics object is malformed: ', dateKey);
    }
  }

  return aggregatesForRep;
}

function getAggregateMetrics(listWithMetricsPerRep) {
  const globals = new Metrics('Total');

  const listWithAggregatesPerRep = listWithMetricsPerRep.map(repObject =>
    getAggregatesForIndividualRep(repObject, globals),
  );

  return {
    globals: globals.createRows(),
    repList: listWithAggregatesPerRep.map(repMetrics =>
      repMetrics.createRows(),
    ),
  };
}

function AdminDashboardPage(props) {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);

  const [metrics, setMetrics] = useState({});

  useEffect(() => {
    firebase
      .getMetrics()
      .then(snap => {
        const docs = snap.docs.map(doc => doc.data());
        setMetrics(getAggregateMetrics(docs));
      })
      .catch(err => {
        console.error('Metrics fetch error: ', err);
        toast.error('Error al cargar datos de analítica.');
      });
  }, []);

  // Consolidate all metrics - global and individual/per rep into single list
  // Reason to keep them separate at the start is in case we want to do
  // more precise manipulation in the future; ex: putting globals somewhere else
  let metricsList = [];
  if (metrics && metrics.globals && metrics.repList.length > 0) {
    metricsList = [metrics.globals, ...metrics.repList];
  }

  return (
    <SubViewContainer title="Métricas de uso">
      <Grid className={classes.gridContainer} container spacing={2}>
        {metricsList.length > 0 &&
          metricsList.map((metricsItem, i) => (
            <Fade key={metricsItem.subject} in timeout={i * 350}>
              <Grid item xs={12} md={4}>
                <MetricsCard metrics={metricsItem} />
              </Grid>
            </Fade>
          ))}
      </Grid>
      <Typography variant="body2" color="textSecondary">
        Nota: Si un usuario no aparece, es que todavía no ha tenido actividad en
        el sistema
      </Typography>
    </SubViewContainer>
  );
}

const authorizationCondition = authUser =>
  authUser.isAdmin || authUser.isSuperAdmin;

export default compose(
  withAuthorization(authorizationCondition),
  withTopBar(),
)(AdminDashboardPage);
