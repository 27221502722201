import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import SupportingActionInfoChipList from './SupportingActionInfoChipList';

const useStyles = makeStyles(theme => ({
  image: {
    height: 300,
  },
  shortDescription: {
    marginBottom: theme.spacing(2),
  },
  chipCaption: {
    marginBottom: theme.spacing(1),
  },
}));

export const SupportingActionViewHeader = props => {
  const { supportingAction } = props;
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} md={7}>
        <Card elevation={0} square>
          <CardMedia
            image={supportingAction.imageUrl + '?h=600'}
            className={classes.image}
          />
        </Card>
      </Grid>
      <Grid item md={5}>
        <Typography variant="h4" gutterBottom>
          {supportingAction.title}
        </Typography>
        <Typography variant="subtitle1" className={classes.shortDescription}>
          {supportingAction.shortDescription}
        </Typography>
        <Typography variant="subtitle2" className={classes.chipCaption}>
          Válido para:
        </Typography>
        <SupportingActionInfoChipList division={supportingAction.productType} />
      </Grid>
    </>
  );
};
