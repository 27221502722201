import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  RadialBarChart,
  RadialBar,
  Label,
  ResponsiveContainer,
  PolarAngleAxis,
} from 'recharts';
import { RADIAL_CHART_BG_FILL } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 250,
    marginBottom: theme.spacing(1),
  },
}));

function SinglePotentialChart(props) {
  const classes = useStyles();

  const { value, fill } = props;

  const data = [{ name: 'Anterior', value, fill }];

  return (
    <div className={classes.root}>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <RadialBarChart
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          startAngle={90}
          endAngle={-270}
          barSize={45}
          innerRadius="70%"
          outerRadius="130%"
        >
          <PolarAngleAxis
            type="number"
            domain={[0, 100]}
            angleAxisId={0}
            tick={false}
          />
          <RadialBar
            dataKey={'value'}
            angleAxisId={0}
            data={[data[0]]}
            background={{ fill: RADIAL_CHART_BG_FILL }}
          />
        </RadialBarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default SinglePotentialChart;
