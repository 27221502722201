import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SinglePotentialChart from './SinglePotentialChart';
import Typography from '@material-ui/core/Typography';
import { RADIAL_CHART_BG_FILL } from '../constants';
import ChartLegend from './ChartLegend';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  columnTitle: {
    marginBottom: theme.spacing(2),
  },
  missingText: {
    margin: theme.spacing(2, 4),
    fontSize: '0.9rem',
    fontWeight: 300,
  },
  missingHeader: {
    fontWeight: 400,
  },
  legendRoot: {
    width: '87%',
  },
}));

// This is a hack to accommodate a customer request
// Although there is a single coloring potential, they want to split it in two (color / balayage)
// This will have to be updated accordingly every time potentials change
// TODO: Find a potentially cleaner solution, and make 'coloraciones' constant
function getPotentialLegendText(categoryName, potentialSalesUnits) {
  if (categoryName === 'coloraciones') {
    return 'Tu potencial de venta es de 40 colores y 16 balayages (56 total)';
  } else {
    return `Tu potencial total de venta es de ${potentialSalesUnits} ${categoryName}`;
  }
}

export default function SinglePotentialChartContainer(props) {
  const classes = useStyles();
  const {
    name,
    actualSalesUnits,
    potentialSalesUnits,
    potentialMetPercentage,
    fill,
  } = props;

  // TODO: Move missing data component below - more readable
  const missingDataComponent = (
    <Typography
      className={classes.missingText}
      color="textSecondary"
      align="center"
    >
      <span className={classes.missingHeader}>Faltan datos de venta</span>
      <br />
      Introduce los datos para ver la información de potencial
    </Typography>
  );

  const legendItems = [
    {
      text: `Has vendido ${actualSalesUnits} ${name}`,
      fill,
    },
    {
      text: getPotentialLegendText(name, potentialSalesUnits),
      fill: RADIAL_CHART_BG_FILL,
    },
  ];

  return (
    <>
      <SinglePotentialChart value={potentialMetPercentage} fill={fill} />
      <div>
        {potentialMetPercentage === 0 ? (
          missingDataComponent
        ) : (
          <ChartLegend
            className={classes.legendRoot}
            legendItems={legendItems}
          />
        )}
      </div>
    </>
  );
}
