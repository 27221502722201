import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import withTopBar from './withTopBar';
import withAuthorization from './withAuthorization';
import SubViewContainer from './SubViewContainer';
import PlanRevisionTable from './PlanRevisionTable/PlanRevisionTable';
import PaperHeaderContainer from './PaperHeaderContainer';
import {
  fetchAndLoadPlanRevision,
  savePlanRevision,
  setComment,
  setRevisionRows,
} from '../state/actions/plan-revision';
import PlanRevisionHeader from './PlanRevisionHeader';

function PlanRevisionPage({
  match,
  isNetworkBusy,
  fetchAndLoadPlanRevision,
  planRevisionData,
  setRevisionRows,
  setComment,
  savePlanRevision,
}) {
  const history = useHistory();

  const {
    customerName,
    activationDate,
    finishDate,
    numCollabs,
    nextSalesPeriod,
    revisionRows,
    summaryRows,
    comment,
  } = planRevisionData;

  const { planId } = match.params;

  // TODO: Move plan fetching logic to Redux
  useEffect(() => {
    if (planId) {
      fetchAndLoadPlanRevision(history, planId);
    }
  }, []);

  // TODO: Move save handler to Redux
  function handleSave() {
    savePlanRevision(history);
  }

  const HeaderComponent = () => (
    <PlanRevisionHeader
      handleSave={handleSave}
      isNetworkBusy={isNetworkBusy}
      customerName={customerName}
      activationDate={activationDate}
      finishDate={finishDate}
    />
  );

  return (
    <SubViewContainer CustomHeaderComponent={HeaderComponent}>
      {revisionRows.length === 0 ? (
        'Cargando...'
      ) : (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <PaperHeaderContainer title="Objetivos vs. venta real" isFlat>
                <PlanRevisionTable
                  rows={revisionRows}
                  summaryRows={summaryRows}
                  setRows={setRevisionRows}
                  nextSalesPeriod={nextSalesPeriod}
                  numCollabs={numCollabs}
                />
              </PaperHeaderContainer>
            </Grid>
            <Grid item xs={4}>
              <PaperHeaderContainer title="Comentarios" isFlat>
                <TextField
                  placeholder="Escribe aquí tus comentarios (opcional)"
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                  variant="outlined"
                  multiline
                  rows={6}
                  rowsMax={Infinity}
                  fullWidth
                />
              </PaperHeaderContainer>
            </Grid>
          </Grid>
        </div>
      )}
    </SubViewContainer>
  );
}

function mapStateToProps(state) {
  return {
    planRevisionData: state.planRevision,
    isNetworkBusy: state.ui.isNetworkBusy,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAndLoadPlanRevision: (history, planId) =>
      dispatch(fetchAndLoadPlanRevision(history, planId)),
    setRevisionRows: revisionRows => dispatch(setRevisionRows(revisionRows)),
    setComment: comment => dispatch(setComment(comment)),
    savePlanRevision: history => dispatch(savePlanRevision(history)),
  };
}

const authorizationCondition = authUser => !!authUser;

export default compose(
  withAuthorization(authorizationCondition),
  withTopBar(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PlanRevisionPage);
