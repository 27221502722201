import { combineReducers } from 'redux';
import businessPlan from './business-plan';
import ui from './ui';
import session from './session';
import planRevision from './plan-revision';

const combinedReducers = combineReducers({
  ui,
  session,
  businessPlan,
  planRevision,
});

export default combinedReducers;
