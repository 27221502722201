import { PRODUCT_TYPES } from '../../constants';

export const presetRows = [
  {
    id: 0,
    product: 'Color + Balayage',
    salesWeekCollab: 4,
    type: PRODUCT_TYPES.coloring,
    averageSalesPrice: 25,
    averageUnitCost: 0,
  },
  {
    id: 1,
    product: 'Tratamiento',
    salesWeekCollab: 3,
    type: PRODUCT_TYPES.technical,
    averageSalesPrice: 10,
    averageUnitCost: 0,
  },
  {
    id: 2,
    product: 'Reventa',
    salesWeekCollab: 2,
    type: PRODUCT_TYPES.retail,
    averageSalesPrice: 18,
    averageUnitCost: 0,
  },
];
