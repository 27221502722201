import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  EditingState,
  IntegratedFiltering,
  SearchState,
} from '@devexpress/dx-react-grid';
import {
  Grid as DxGrid,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import BrandsTypeProvider from './BrandsTypeProvider';

const useStyles = makeStyles(theme => ({
  tableHeaderContent: {
    color: theme.palette.text.secondary,
  },
}));

const columns = [
  { title: 'Ref. de cliente', name: 'id' },
  { title: 'Nombre', name: 'companyName' },
  { title: 'Comercial', name: 'repEmail' },
  { title: 'Organización', name: 'brandScopes' },
];

const columnExtensions = [
  {
    columnName: 'id',
    width: 110,
  },
];

export default function AdminCustomerManagementTable({
  rows,
  searchValue,
  onSearchChange,
  onCommitChanges,
}) {
  const classes = useStyles();

  const vtRef = useRef();

  return (
    <DxGrid rows={rows} columns={columns} getRowId={row => row.docId}>
      <BrandsTypeProvider for={['brandScopes']} />
      <SearchState value={searchValue} onValueChange={onSearchChange} />
      <EditingState onCommitChanges={onCommitChanges} />
      <IntegratedFiltering />
      <VirtualTable
        height={795}
        ref={vtRef}
        columnExtensions={columnExtensions}
      />
      <TableHeaderRow
        contentComponent={({ children, ...props }) => {
          return (
            <TableHeaderRow.Content
              className={classes.tableHeaderContent}
              {...props}
            >
              {children}
            </TableHeaderRow.Content>
          );
        }}
      />
      <TableEditRow />
      <TableEditColumn
        showAddCommand
        showEditCommand
        showDeleteCommand
        messages={{
          addCommand: 'Añadir',
          editCommand: 'Editar',
          deleteCommand: 'Borrar',
          commitCommand: 'Guardar',
          cancelCommand: 'Cancelar',
        }}
        width={210}
      />
    </DxGrid>
  );
}
