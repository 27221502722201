import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  COLORING_POTENTIAL,
  RETAIL_POTENTIAL,
  SERVICE_POTENTIAL,
} from '../../constants';
import {
  getNextColoringSales,
  getNextRetailSales,
  getNextSalesPeriod,
  getNextServiceSales,
  getNumCollabs,
  getPrevColoringSales,
  getPrevRetailSales,
  getPrevSalesPeriod,
  getPrevServiceSales,
} from './common';

function getPotentialMetPercentage(
  prevSales,
  prevSalesPeriod,
  numCollabs,
  potential,
) {
  if (!prevSales || !prevSalesPeriod || !numCollabs) {
    return 0;
  }
  return _.round(
    (prevSales / prevSalesPeriod / numCollabs / potential) * 100,
    0,
  );
}

function getPotentialInUnits(potentialPerCollabWeek, period, numCollabs) {
  return potentialPerCollabWeek * period * numCollabs;
}

function createPotentialSelector(salesGetter, salesPeriodGetter, rawPotential) {
  return createSelector(
    [salesGetter, getNumCollabs, salesPeriodGetter],
    (salesInUnits, numCollabs, salesPeriod) => {
      return {
        metInPercentage: getPotentialMetPercentage(
          salesInUnits,
          salesPeriod,
          numCollabs,
          rawPotential,
        ),
        inUnits: getPotentialInUnits(rawPotential, salesPeriod, numCollabs),
      };
    },
  );
}

export const getPrevRetailPotentials = createPotentialSelector(
  getPrevRetailSales,
  getPrevSalesPeriod,
  RETAIL_POTENTIAL,
);

export const getPrevServicePotentials = createPotentialSelector(
  getPrevServiceSales,
  getPrevSalesPeriod,
  SERVICE_POTENTIAL,
);

export const getPrevColoringPotentials = createPotentialSelector(
  getPrevColoringSales,
  getPrevSalesPeriod,
  COLORING_POTENTIAL,
);

export const getNextRetailPotentials = createPotentialSelector(
  getNextRetailSales,
  getNextSalesPeriod,
  RETAIL_POTENTIAL,
);

export const getNextServicePotentials = createPotentialSelector(
  getNextServiceSales,
  getNextSalesPeriod,
  SERVICE_POTENTIAL,
);

export const getNextColoringPotentials = createPotentialSelector(
  getNextColoringSales,
  getNextSalesPeriod,
  COLORING_POTENTIAL,
);
