import React from 'react';
import { format, parseISO, isValid } from 'date-fns';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

function DateFormatter(props) {
  const date = parseISO(props.value);
  let value = '-';
  if (isValid(date)) {
    value = format(date, 'dd/MM/yy');
  }
  return value;
}

export default function DateTypeProvider(props) {
  return <DataTypeProvider formatterComponent={DateFormatter} {...props} />;
}
