import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from '../../firebase';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  loginContainer: {
    position: 'absolute',
    width: 300,
    top: '25%',
    left: '50%',
    transform: 'translate(-50%, -25%)',
    '& > *': {
      marginBottom: theme.spacing(4),
    },
  },
}));

export default function AdminLoginPage(props) {
  const firebase = useContext(FirebaseContext);
  const history = useHistory();
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleLogin() {
    firebase
      .adminLogin(email, password)
      .then(result => history.push('/'))
      .catch(err => console.error('error: ', err));
  }

  return (
    <div className={classes.loginContainer}>
      <TextField
        fullWidth
        value={email}
        label="Usuario"
        variant="outlined"
        onChange={ev => setEmail(ev.target.value)}
      />
      <TextField
        fullWidth
        value={password}
        label="Contraseña"
        type="password"
        variant="outlined"
        onChange={ev => setPassword(ev.target.value)}
      />
      <Button
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        onClick={handleLogin}
      >
        Acceder
      </Button>
    </div>
  );
}
