import React, { useState } from 'react';
import { connect } from 'react-redux';
import { List } from '@material-ui/core/index';
import { SupportingActionListItem } from './SupportingActionListItem';
import { toggleModal } from '../../state/actions/ui';
import { toggleSupportingAction } from '../../state/actions/business-plan';
import { SupportingActionDetailView } from './SupportingActionDetailView';
import { SupportingActionWarningModal } from './SupportingActionWarningModal';

const SupportingActionList = props => {
  const { list, toggleSupportingAction, isEditable, toggleModal } = props;

  const [warningModalActionId, setWarningModalActionId] = useState(undefined);

  return (
    <>
      <SupportingActionWarningModal
        isOpen={!!warningModalActionId}
        onConfirm={() => {
          toggleSupportingAction(warningModalActionId);
          setWarningModalActionId(undefined);
        }}
        onCancel={() => setWarningModalActionId(undefined)}
      />
      <List>
        {list.map(supportingAction => {
          const modalContent = (
            <SupportingActionDetailView
              supportingAction={supportingAction}
              isEditable={isEditable}
            />
          );

          return (
            <SupportingActionListItem
              key={supportingAction.id}
              imgUrl={supportingAction.imageUrl}
              primaryText={supportingAction.title}
              secondaryText={supportingAction.shortDescription}
              division={supportingAction.productType}
              isCurrent={supportingAction.isCurrent}
              onClickPrimary={() => toggleModal(modalContent)}
              onToggle={
                // If the action is old and selected, we show a modal warning that
                // deselecting it and saving the plan will mean losing it permanently
                // Otherwise we just use a normal toggle function
                !supportingAction.isCurrent && supportingAction.isSelected
                  ? () => setWarningModalActionId(supportingAction.id)
                  : () => toggleSupportingAction(supportingAction.id)
              }
              isSelected={supportingAction.isSelected}
              isEditable={isEditable}
            />
          );
        })}
      </List>
    </>
  );
};

const actionCreators = {
  toggleSupportingAction,
  toggleModal,
};

const ConnectedBusinessActionList = connect(
  undefined,
  actionCreators,
)(SupportingActionList);

export default ConnectedBusinessActionList;
