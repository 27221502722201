import { toast } from 'react-toastify/dist/index';

export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const TOGGLE_IS_NETWORK_BUSY = 'TOGGLE_IS_NETWORK_BUSY';
export const CHANGE_SUPPORTING_ACTION_FILTERS_UI =
  'CHANGE_SUPPORTING_ACTION_FILTERS_UI';
export const SHOW_BACKDROP_WITH_SPINNER = 'SHOW_BACKDROP_WITH_SPINNER';

export function setActiveStep(payload) {
  return { type: SET_ACTIVE_STEP, payload };
}

export function toggleModal(payload) {
  return { type: TOGGLE_MODAL, payload };
}

export function setCustomerList(list) {
  return { type: SET_CUSTOMER_LIST, payload: list };
}

export function toggleIsNetworkBusy(isSaving) {
  return { type: TOGGLE_IS_NETWORK_BUSY, payload: isSaving };
}

export function showBackdropWithSpinner(shouldShow) {
  return { type: SHOW_BACKDROP_WITH_SPINNER, payload: shouldShow };
}

export function getCustomerList(firebase, repId) {
  return async function(dispatch, getState) {
    try {
      const snapshot = await firebase.getCustomers(repId);
      const customerList = snapshot.docs.map(doc => doc.data());
      dispatch(setCustomerList(customerList));
    } catch (err) {
      console.error('getCustomerList error: ', err);
      toast.error(
        'Error al cargar lista de clientes. Por favor inténtalo de nuevo.',
      );
    }
  };
}

export function changeSupportingActionFilterUi(brand, productType, isActive) {
  return {
    type: CHANGE_SUPPORTING_ACTION_FILTERS_UI,
    payload: {
      brand,
      productType,
      isActive,
    },
  };
}
