import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { handleQualitativeObjectivesChange } from '../state/actions/business-plan';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles(theme => ({
  textArea: {
    padding: theme.spacing(2),
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    borderColor: theme.palette.divider,
  },
}));

function QualitativeObjectives({
  qualitativeObjectives,
  handleQualitativeObjectivesChange,
  isEditable = true,
}) {
  const classes = useStyles();

  return (
    <TextareaAutosize
      className={classes.textArea}
      rowsMin={5}
      aria-label="Qualitative objectives"
      placeholder="Anota los objetivos cualitativos del plan"
      value={qualitativeObjectives}
      onChange={ev => handleQualitativeObjectivesChange(ev.target.value)}
      readOnly={!isEditable}
    />
  );
}

function mapStateToProps(state) {
  return {
    qualitativeObjectives: state.businessPlan.qualitativeObjectives,
  };
}

const mapDispatchToProps = {
  handleQualitativeObjectivesChange,
};

const ConnectedQualitativeObjectives = connect(
  mapStateToProps,
  mapDispatchToProps,
)(QualitativeObjectives);

export default ConnectedQualitativeObjectives;
