import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { orange, red } from '@material-ui/core/colors';
import { PLAN_STATES } from '../../constants';

function daysToPeriodStrings(days) {
  if (days === 0) {
    return 'Hoy';
  }

  const prefix = days < 0 ? 'En' : 'Hace';

  const absDays = Math.abs(days);

  if (absDays < 14) {
    return `${prefix} ${absDays} días`;
  } else {
    return `${prefix} ${Math.trunc(absDays / 7)} semanas`;
  }
}

function ExpirationFormatter({ value, row }) {
  if (row.state === PLAN_STATES.inProgress) {
    const periodString = daysToPeriodStrings(value);
    if (value > -14) {
      const color = value >= 0 ? red[500] : orange[500];
      return <span style={{ color }}>{periodString}</span>;
    }
    return periodString;
  } else {
    return '-';
  }
}

export default function ExpirationTypeProvider(props) {
  return (
    <DataTypeProvider formatterComponent={ExpirationFormatter} {...props} />
  );
}
