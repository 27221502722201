import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import { blue, green, red, grey } from '@material-ui/core/colors/index';
import SupportingActionInfoChip from './SupportingActionInfoChip';
import { SUPPORTING_ACTION_CHIP_LABELS } from '../../constants';

const useStyles = makeStyles(theme => ({
  chipListRoot: {
    display: 'inline',
    verticalAlign: 'middle',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

function getChipBackgroundColor(label) {
  if (label === SUPPORTING_ACTION_CHIP_LABELS.retail) {
    return green[50];
    // TODO: Hack - see below
  } else if (label === 'Tratamiento') {
    return blue[50];
  } else if (label === SUPPORTING_ACTION_CHIP_LABELS.color) {
    return red[50];
  }
  return grey[100];
}

const SupportingActionInfoChipList = ({ labelList }) => {
  const classes = useStyles();

  return (
    labelList instanceof Array &&
    labelList.length > 0 && (
      <div className={classes.chipListRoot}>
        {labelList.map(label => {
          // TODO: This is a hack; must be properly changed in content model
          if (label === 'Servicio') {
            label = 'Tratamiento';
          }
          return (
            <SupportingActionInfoChip
              key={label}
              label={label}
              backgroundColor={getChipBackgroundColor(label)}
            />
          );
        })}
      </div>
    )
  );
};

export default SupportingActionInfoChipList;
