import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  modalBody: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  commitButton: {
    marginRight: theme.spacing(1),
  },
}));

export const SupportingActionWarningModal = ({
  isOpen,
  onConfirm,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={isOpen} onClose={onCancel}>
      <div className={classes.modalBody}>
        <Typography variant="body1" paragraph>
          Este apoyo ha sido archivado. Si se quita del plan y éste se guarda,
          ya no se podrá volver a añadir. ¿Seguro que quieres quitarlo?
        </Typography>
        <Button
          className={classes.commitButton}
          variant="contained"
          color="secondary"
          onClick={onConfirm}
        >
          Confirmar
        </Button>
        <Button onClick={onCancel}>Cancelar</Button>
      </div>
    </Modal>
  );
};
