import React, { useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';
import SaveIcon from '@material-ui/icons/SaveSharp';
import ButtonGroup from '@material-ui/core/ButtonGroup/index';
import Popper from '@material-ui/core/Popper/index';
import Grow from '@material-ui/core/Grow/index';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/index';
import MenuList from '@material-ui/core/MenuList/index';
import MenuItem from '@material-ui/core/MenuItem/index';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';

import Spinner from './Spinner';

const useStyles = makeStyles(theme => ({
  saveButton: {
    backgroundColor: blue[100],
    '&:hover': {
      backgroundColor: blue[200],
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.1rem',
    },
  },
}));

export default function SaveAndCommitButtonGroup({
  isSaving,
  handleSave,
  setModalOpen,
}) {
  const classes = useStyles();

  const anchorRef = useRef(null);
  const [selectedIndex] = useState(0);

  const [isPopperOpen, setPopperOpen] = React.useState(false);

  const options = ['Guardar', 'Guardar y comprometer'];

  const handleMenuItemClick = (event, index) => {
    setPopperOpen(false);
    // Depending on the option, do one or the other
    if (index === 0) {
      handleSave();
    } else {
      setModalOpen(true);
    }
  };

  const handleToggle = () => {
    setPopperOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setPopperOpen(false);
  };

  return (
    <>
      <ButtonGroup
        className={classes.saveButton}
        variant="outlined"
        ref={anchorRef}
        aria-label="split button"
        disabled={isSaving}
      >
        <Button
          onClick={() => handleSave(false)}
          startIcon={isSaving ? <Spinner /> : <SaveIcon />}
          size="small"
        >
          {options[0]}
        </Button>
        <Button
          size="small"
          aria-controls={isPopperOpen ? 'split-button-menu' : undefined}
          aria-expanded={isPopperOpen ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={isPopperOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
