import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontSize: '2em',
  },
  sectionTitleDivider: {
    margin: theme.spacing(1, 0, 2),
  },
}));

// TODO: isEditable is being passed from very far up
// This changes depending on the view / mode we are in... It's better managed in Redux
export const SupportingActionDetailViewSection = props => {
  const { title, component } = props;
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.sectionTitle} variant="h5">
        {title}
      </Typography>
      <Divider className={classes.sectionTitleDivider} />
      {component}
    </>
  );
};
