import React, { useState } from 'react';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid as DxGrid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui';
import { PRODUCT_TYPES } from '../../constants';
import { connect } from 'react-redux';
import NumberTypeProvider, {
  EditableCurrencyTypeProvider,
  CurrencyTypeProvider,
} from './NumberTypeProvider';
import ProductTypeProvider from './ProductTypeProvider';
import CustomCommandComponent from './CustomCommandComponent';
import { defaultColumnExtensions, generateColumns } from './config';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import clsx from 'clsx';
import blue from '@material-ui/core/colors/blue';
import { handleBusinessModelRowsChange } from '../../state/actions/business-plan';

const COMMAND_COL_WIDTH = 50;

const useStyles = makeStyles(theme => ({
  tableRoot: {
    '& th': {
      padding: 6,
      fontSize: '0.74rem',
      color: theme.palette.text.secondary,
    },
    '& td': {
      padding: '6px 6px',
      fontSize: '0.74rem',
    },
    '& button': {
      padding: 0,
    },
    '& input': {
      fontSize: '0.74rem',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem',
    },
  },
  summaryRoot: {
    '& tr': {
      backgroundColor: lighten(blue[50], 0.9),
    },
    '& tr:last-child td': {
      fontSize: '0.78rem',
      backgroundColor: lighten(blue[50], 0.6),
    },
  },
}));

function isColumnNonEditable(columnName) {
  return (
    columnName === 'sellOut' ||
    columnName === 'income' ||
    columnName === 'profit' ||
    columnName === 'totalCost'
  );
}

const CustomCell = ({ column, style, onClick, isSummary, ...restProps }) => {
  let additionalStyle = { ...style };
  if (isColumnNonEditable(column.name) && !isSummary) {
    additionalStyle = { backgroundColor: grey[50] };
  }
  if (column.name === 'profit') {
    additionalStyle.fontWeight = 700;
  }
  return (
    <Table.Cell
      {...restProps}
      style={additionalStyle}
      tabIndex={0}
      onClick={onClick}
      // Assigning onClick behavior to onFocus allows switching with
      // tab and entering edit mode on the next cell
      onFocus={onClick}
    />
  );
};

const CustomSummaryCell = props => {
  return <CustomCell {...props} isSummary />;
};

function TableComponent({ ...restProps }) {
  const classes = useStyles();
  return <Table.Table className={classes.tableRoot} {...restProps} />;
}

function SummaryTableComponent({ ...restProps }) {
  const classes = useStyles();
  return (
    <Table.Table
      className={clsx(classes.tableRoot, classes.summaryRoot)}
      {...restProps}
    />
  );
}

function CustomInlineEditingCell({ column, ...restProps }) {
  const isEditable = !isColumnNonEditable(column.name);

  return (
    <TableInlineCellEditing.Cell
      {...restProps}
      column={column}
      editingEnabled={isEditable}
    />
  );
}

const getRowId = row => row.id;

const isSummaryColumn = colName => {
  return colName === 'product' || colName === 'type' || colName === 'sellOut';
};

function SellOutTable(props) {
  const {
    numCollabs,
    rows,
    setRows,
    summaryRows,
    isEditable,
    nextSalesPeriod,
    isAbridged,
    isColoringActive,
  } = props;

  // Table columns should be kept as state
  // It's in the docs, plus what if we wanted to add a column?
  // https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/-accessors/
  // Left it like this b/c I couldn't get it to re-render otherwise
  let columns = generateColumns(numCollabs, nextSalesPeriod);
  let [columnExtensions] = useState(defaultColumnExtensions);

  if (isAbridged) {
    columns = columns.filter(col => isSummaryColumn(col.name));
  }

  const minWidth = isAbridged ? 0 : 690;

  // TODO: Abstract markers of type column into own config files
  const [booleanColumns] = useState(['type']);
  const [numberColumns] = useState(['salesWeekCollab']);

  const [currencyColumns] = useState(['income', 'profit', 'totalCost']);

  const [editableCurrencyColumns] = useState([
    'averageUnitCost',
    'averageSalesPrice',
  ]);

  const [editingCells, setEditingCells] = useState([]);

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId =
        rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
      setEditingCells([
        { rowId: startingAddedId, columnName: columns[0].name },
      ]);
    }
    if (changed) {
      changedRows = rows.map(row =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row,
      );
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter(row => !deletedSet.has(row.id));
    }
    setRows(changedRows);
  };

  const addEmptyRow = () =>
    commitChanges({
      added: [
        {
          product: '',
          type: PRODUCT_TYPES.retail,
          salesWeekCollab: 0,
          averageUnitCost: 0,
          averageSalesPrice: 0,
        },
      ],
    });

  let summaryColumns = [
    ...columns.map(column => ({ name: column.name, title: column.title })),
  ];

  if (isEditable) {
    summaryColumns.unshift({ title: 'Command', name: 'command' });
  }

  const summaryColumnExtensions = [
    { columnName: 'command', width: COMMAND_COL_WIDTH },
    ...columnExtensions,
  ];

  return (
    <div style={{ minWidth }}>
      <DxGrid rows={rows} columns={columns} getRowId={getRowId}>
        <CurrencyTypeProvider
          for={currencyColumns}
          extensions={{ setEditingCells, rows, setRows }}
        />
        <EditableCurrencyTypeProvider
          for={editableCurrencyColumns}
          extensions={{ setEditingCells, rows, setRows }}
        />
        <NumberTypeProvider
          for={numberColumns}
          extensions={{ setEditingCells, rows, setRows }}
        />
        <ProductTypeProvider
          for={booleanColumns}
          extensions={{
            commitChanges,
            setEditingCells,
            columns,
            rows,
            setRows,
            isAbridged,
            isColoringActive,
          }}
        />
        <EditingState
          onCommitChanges={commitChanges}
          editingCells={editingCells}
          onEditingCellsChange={setEditingCells}
          addedRows={[]}
          onAddedRowsChange={addEmptyRow}
        />
        <Table
          tableComponent={TableComponent}
          columnExtensions={columnExtensions}
          cellComponent={CustomCell}
          messages={{ noData: 'No hay datos' }}
        />
        <TableHeaderRow />
        {isEditable && (
          <TableInlineCellEditing
            selectTextOnEditStart
            cellComponent={CustomInlineEditingCell}
            columnExtensions={columnExtensions}
          />
        )}
        {isEditable && (
          <TableEditColumn
            width={COMMAND_COL_WIDTH}
            commandComponent={CustomCommandComponent}
            showAddCommand
            showDeleteCommand
          />
        )}
      </DxGrid>
      <DxGrid rows={summaryRows} columns={summaryColumns}>
        <ProductTypeProvider for={booleanColumns} extensions={{ isAbridged }} />
        <CurrencyTypeProvider for={currencyColumns} />
        <NumberTypeProvider for={numberColumns} />
        <Table
          tableComponent={SummaryTableComponent}
          columnExtensions={
            isEditable ? summaryColumnExtensions : columnExtensions
          }
          cellComponent={CustomSummaryCell}
        />
      </DxGrid>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    numCollabs: state.businessPlan.numCollabs,
    rows: state.businessPlan.salesTargets,
    summaryRows: state.businessPlan.summaryRows,
    nextSalesPeriod: state.businessPlan.nextSalesPeriod,
    isColoringActive: state.businessPlan.isColoringActive,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRows: rows => dispatch(handleBusinessModelRowsChange(rows)),
  };
}

const ConnectedSellOutTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SellOutTable);

export default ConnectedSellOutTable;
