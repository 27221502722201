import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/analytics';
import { addWeeks } from 'date-fns';
import { primaryConfig } from './config';
import { PLAN_META, PLAN_STATES } from '../constants';
import { validatePlan, validateRevision } from '../utils/validation';
import { serializeBusinessPlan } from '../utils/serialization';

// TODO: Document this class and comment all methodsP
// TODO: Think about separating the api from Firebase
// We would export only the constructed firebase class - no methods
// API methods would go in their own class

class Firebase {
  constructor() {
    app.initializeApp(primaryConfig);
    // Only initialize Analytics in production
    // (In test, it complains about not being run in browser)
    if (process.env.NODE_ENV === 'production') {
      app.analytics();
    }
    this.db = app.firestore();
    if (process.env.NODE !== 'production') {
      // In development, use emulator
      app
        .app()
        .functions('europe-west3')
        .useEmulator('localhost', 5001);
    }
    this.functions = app.app().functions('europe-west3');
    this.auth = app.auth();
  }

  customers = () => this.db.collection('customers');

  plans = () => this.db.collection('plans');

  metrics = () => this.db.collection('metrics');

  onAuthListener = (next, fallback) => {
    this.auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        let userInStore = {
          uid: authUser.uid,
          email: authUser.email,
        };
        try {
          const decodedToken = await authUser.getIdTokenResult();
          userInStore.firstName =
            decodedToken?.claims?.firebase?.sign_in_attributes?.[
              'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'
            ];
          // Is this something we should leave coded, and just decode
          // on every authorization access in the app?
          userInStore.isAdmin = decodedToken?.claims?.isAdmin;
          userInStore.isSuperAdmin = decodedToken?.claims?.isSuperAdmin;
          userInStore.brandScopes = decodedToken?.claims?.brandScopes;
        } catch (err) {
          // We do nothing
          console.error('onAuthState token decode error: ', err);
        }
        next(userInStore);
      } else {
        fallback();
      }
    });
  };

  login = (next, error) => {
    const provider = new app.auth.SAMLAuthProvider('saml.boost-your-business');
    return this.auth.signInWithPopup(provider);
  };

  logout = () => {
    return this.auth.signOut();
  };

  adminLogin = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  };

  savePlan = async (planData, shouldCommit, shouldShare) => {
    const { planId } = planData;

    let validated;
    try {
      const serialized = serializeBusinessPlan(planData);
      validated = validatePlan(serialized);
    } catch (err) {
      throw new Error(
        `Hay un error. Por favor, revisa los datos. \n \n ${err.message}`,
      );
    }

    const timestamp = app.firestore.FieldValue.serverTimestamp();

    let planDoc = {
      ...validated,
      modifiedAt: timestamp,
    };

    if (shouldCommit) {
      planDoc.state = PLAN_STATES.inProgress;
      planDoc[PLAN_META.activationDate] = timestamp;
      planDoc[PLAN_META.finishDate] = app.firestore.Timestamp.fromDate(
        addWeeks(Date.now(), planData.nextSalesPeriod),
      );
    } else if (!shouldCommit && !planId) {
      planDoc.state = PLAN_STATES.pending;
    }

    if (shouldShare) {
      planDoc.shared = true;
    }

    // TODO: Move this inside the try-catch block!
    if (!planId) {
      planDoc.createdAt = timestamp;
      const doc = this.plans().doc();
      await doc.set(planDoc);
      return doc.id;
    }

    try {
      await this.plans()
        .doc(planId)
        .update(planDoc);
    } catch (err) {
      console.error('firebase/savePlan error: ', err);
      throw new Error(
        'Error al guardar. \n' +
          'Por favor inténtalo de nuevo. ' +
          'Si el problema persiste, contacta con el administrador',
      );
    }

    return planId;
  };

  deletePlan = async planId => {
    return this.plans()
      .doc(planId)
      .delete();
  };

  savePlanRevision = (planRevisionData, planId) => {
    let validatedData;
    try {
      validatedData = validateRevision(planRevisionData);
    } catch (err) {
      throw new Error(
        'Hay un error, por favor revisa los datos. \n \n' + err.message,
      );
    }
    try {
      return this.plans()
        .doc(planId)
        .update({ ...validatedData, state: PLAN_STATES.finalized });
    } catch (err) {
      throw new Error('Error al guardar. Por favor, inténtalo de nuevo.');
    }
  };

  getAllPlans = repId => {
    return this.plans()
      .where('repId', '==', repId)
      .get();
  };

  getPlansByCustomerId = (customerId, repEmail) => {
    return this.plans()
      .where('customerId', '==', customerId)
      .where('repEmail', '==', repEmail)
      .get();
  };

  getPlan = planId => {
    return this.plans()
      .doc(planId)
      .get();
  };

  fetchPlanActions = planId => {
    return this.functions.httpsCallable('fetchPlanActions')({
      planId,
    });
  };

  getCustomers = repEmail => {
    return this.customers()
      .where('repEmail', '==', repEmail)
      .get();
  };

  getCustomersByBrandScopes = brandScopes => {
    return this.customers()
      .where('brandScopes', 'array-contains-any', brandScopes)
      .get();
  };

  getMetrics = () => {
    return this.metrics().get();
  };

  uploadCustomerCsv = fileData => {
    return this.functions.httpsCallable('uploadCustomerCsv')(fileData);
  };

  addCustomer = rowData => {
    return this.functions.httpsCallable('addCustomer')(rowData);
  };

  editCustomer = rowData => {
    return this.functions.httpsCallable('editCustomer')(rowData);
  };

  removeCustomer = rowData => {
    return this.functions.httpsCallable('removeCustomer')(rowData);
  };
}

export default Firebase;
