import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PlanPage from './PlanPage';
import NotFoundPage from './NotFoundPage';
import PlanViewPage from './PlanViewPage';
import { fetchAndLoadBusinessPlan } from '../state/actions/business-plan';

function PlanEditor(props) {
  const planId = props.match.params.planId;

  const { planDetails, fetchAndLoadBusinessPlan, mode } = props;

  useEffect(() => {
    if (planId) {
      fetchAndLoadBusinessPlan(planId);
    }
  }, []);

  if (mode === 'edit') {
    return (
      <PlanPage
        customerName={planDetails.customerName}
        planId={planDetails.planId}
        customerId={planDetails.customerId}
      />
    );
  } else if (mode === 'view') {
    return (
      <PlanPage
        customerName={planDetails.customerName}
        planId={planDetails.planId}
        customerId={planDetails.customerId}
        mode="view"
      />
    );
  } else if (mode === 'shared') {
    // TODO: Create a document that blocks viewing non-shared docs
    // TODO: Fix - we are exposing the entire plan document
    // There is nothing sensitive in principle, but there may be in the future
    return <PlanViewPage mode={mode} planDetails={planDetails} />;
  } else {
    return <NotFoundPage />;
  }
}

function mapStateToProps(state) {
  return { planDetails: state.businessPlan };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAndLoadBusinessPlan: planId =>
      dispatch(fetchAndLoadBusinessPlan(planId)),
  };
}

const ConnectedPlanEditor = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanEditor);

export default ConnectedPlanEditor;
