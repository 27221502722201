import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import logo from '../../media/boost_logo_black.svg';
import logo from '../../media/boost_logo_black_revised.svg';
// import logoWhite from '../../media/boost_logo_white.svg';
import logoWhite from '../../media/boost_logo_white_revised.svg';

const useStyles = makeStyles(theme => ({
  logoLink: {
    flexGrow: 1,
  },
  logo: {
    height: 36,
    marginRight: theme.spacing(3),
  },
}));

export default function TopLogo(props) {
  const classes = useStyles();
  return (
    <a className={classes.logoLink} href="/">
      <img
        className={classes.logo}
        src={props.displayWhiteLogo ? logoWhite : logo}
        alt="Kérastase Boost Your Business Logo"
      />
    </a>
  );
}
