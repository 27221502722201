import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { blue, green, red } from '@material-ui/core/colors';
import { RADIAL_CHART_BG_FILL } from '../constants';
import {
  getNextColoringPotentials,
  getNextRetailPotentials,
  getNextServicePotentials,
  getPrevColoringPotentials,
  getPrevRetailPotentials,
  getPrevServicePotentials,
} from '../state/selectors/potentials';
import {
  getPrevColoringSalesPWC,
  getPrevRetailSalesPWC,
  getPrevServiceSalesPWC,
} from '../state/selectors/sales-ratios';
import DoublePotentialChart from './DoublePotentialChart';
import ChartLegend from './ChartLegend';

const RETAIL_NEXT_FILL = green[500];
const RETAIL_PREV_FILL = green[100];
const SERVICE_NEXT_FILL = blue[500];
const SERVICE_PREV_FILL = blue[100];
const COLORING_NEXT_FILL = red[300];
const COLORING_PREV_FILL = red[100];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  tabRoot: {
    fontSize: '11.5px',
    minWidth: 10,
  },
}));

function TabPanel(props) {
  const { children, value, index } = props;

  return <div hidden={value !== index}>{children}</div>;
}

function getLegend(dataArray, fillsArray, productLineName) {
  return [
    {
      text: `En el anterior período vendiste ${
        dataArray[0]
      } ${productLineName} (por colaborador por semana)`,
      fill: fillsArray[0],
    },
    {
      text: `El objetivo para el siguiente período es de ${
        dataArray[1]
      } ${productLineName} (por colaborador por semana)`,
      fill: fillsArray[1],
    },
    {
      text: `Tu potencial total  es de ${
        dataArray[2]
      } ${productLineName} (por colaborador por semana)`,
      fill: fillsArray[2],
    },
  ];
}

function PotentialChartTabContainer(props) {
  const {
    isColoringActive,
    retailPotential,
    servicePotential,
    coloringPotential,
    retailPotentialsMet,
    servicePotentialsMet,
    coloringPotentialsMet,
    prevServiceSalesPWC,
    nextServiceSalesPWC,
    prevRetailSalesPWC,
    nextRetailSalesPWC,
    prevColoringSalesPWC,
    nextColoringSalesPWC,
  } = props;
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const serviceLegend = getLegend(
    [prevServiceSalesPWC, nextServiceSalesPWC, servicePotential],
    [SERVICE_PREV_FILL, SERVICE_NEXT_FILL, RADIAL_CHART_BG_FILL],
    'tratamientos',
  );

  const retailLegend = getLegend(
    [prevRetailSalesPWC, nextRetailSalesPWC, retailPotential],
    [RETAIL_PREV_FILL, RETAIL_NEXT_FILL, RADIAL_CHART_BG_FILL],
    'productos',
  );

  const coloringLegend = getLegend(
    [prevColoringSalesPWC, nextColoringSalesPWC, coloringPotential],
    [COLORING_PREV_FILL, COLORING_NEXT_FILL, RADIAL_CHART_BG_FILL],
    'coloraciones / balayages',
  );

  return (
    <div className={classes.root}>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab classes={{ root: classes.tabRoot }} label="Tratamientos" />
        <Tab classes={{ root: classes.tabRoot }} label="Reventa" />
        {isColoringActive && (
          <Tab classes={{ root: classes.tabRoot }} label="Color + B" />
        )}
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <DoublePotentialChart
          data={servicePotentialsMet}
          fills={{ next: SERVICE_NEXT_FILL, prev: SERVICE_PREV_FILL }}
        />
        <ChartLegend legendItems={serviceLegend} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <DoublePotentialChart
          data={retailPotentialsMet}
          fills={{ next: RETAIL_NEXT_FILL, prev: RETAIL_PREV_FILL }}
        />
        <ChartLegend legendItems={retailLegend} />
      </TabPanel>
      {isColoringActive && (
        <TabPanel value={tabValue} index={2}>
          <DoublePotentialChart
            data={coloringPotentialsMet}
            fills={{ next: COLORING_NEXT_FILL, prev: COLORING_PREV_FILL }}
          />
          <ChartLegend legendItems={coloringLegend} />
        </TabPanel>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isColoringActive: state.businessPlan.isColoringActive,
    retailPotential: state.businessPlan.retailPotential,
    servicePotential: state.businessPlan.servicePotential,
    coloringPotential: state.businessPlan.coloringPotential,
    prevServiceSalesPWC: getPrevServiceSalesPWC(state),
    prevRetailSalesPWC: getPrevRetailSalesPWC(state),
    prevColoringSalesPWC: getPrevColoringSalesPWC(state),
    nextServiceSalesPWC: state.businessPlan.summaryRows[0].salesWeekCollab,
    nextRetailSalesPWC: state.businessPlan.summaryRows[1].salesWeekCollab,
    nextColoringSalesPWC: state.businessPlan.summaryRows[2].salesWeekCollab,
    retailPotentialsMet: {
      prev: getPrevRetailPotentials(state).metInPercentage,
      next: getNextRetailPotentials(state).metInPercentage,
    },
    servicePotentialsMet: {
      prev: getPrevServicePotentials(state).metInPercentage,
      next: getNextServicePotentials(state).metInPercentage,
    },
    coloringPotentialsMet: {
      prev: getPrevColoringPotentials(state).metInPercentage,
      next: getNextColoringPotentials(state).metInPercentage,
    },
  };
}

const ConnectedPotentialChartTabContainer = connect(
  mapStateToProps,
  null,
)(PotentialChartTabContainer);

export default ConnectedPotentialChartTabContainer;
