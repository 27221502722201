import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { PLAN_STATES } from '../../constants';
import { green, grey, red } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { FirebaseContext } from '../../firebase';

const useStyles = makeStyles((theme) => ({
  viewButton: {
    color: grey[500],
    marginRight: theme.spacing(1),
  },
  finalizeButton: {
    color: green[300],
  },
  deleteButton: {
    color: red[300],
  },
  smallButton: {
    fontSize: '0.7rem',
    minWidth: 85,
  },
}));

function SmallButton(props) {
  const classes = useStyles();

  return (
    <Button
      className={classes.smallButton}
      size="small"
      variant="outlined"
      {...props}
    >
      {props.children}
    </Button>
  );
}

function ActionFormatter({ row, rows, setRows }) {
  const firebase = useContext(FirebaseContext);
  const classes = useStyles();
  const { state, id } = row;

  let viewUrl = `/plan/view/${id}`;
  if (state === PLAN_STATES.pending) {
    viewUrl = `/plan/edit/${id}`;
  }

  async function handleDelete() {
    try {
      await firebase.deletePlan(id);
      setRows(rows.filter((row) => row.id !== id));
    } catch (err) {
      console.error('handleDelete error: ', err);
      toast.error(
        'Se produjo un error al borrar. Por favor, vuelve a intentarlo.',
      );
    }
  }

  return (
    <Grid spacing={1} container>
      <Grid item>
        {state === PLAN_STATES.pending ? (
          <SmallButton component={Link} to={viewUrl}>
            Ver / Editar
          </SmallButton>
        ) : (
          <SmallButton component={Link} to={viewUrl}>
            Ver
          </SmallButton>
        )}
      </Grid>
      {state === PLAN_STATES.pending && (
        <Grid item>
          <SmallButton color="secondary" onClick={handleDelete}>
            Borrar
          </SmallButton>
        </Grid>
      )}
      {(state === PLAN_STATES.inProgress ||
        state === PLAN_STATES.finalized) && (
        <Grid item>
          <SmallButton component={Link} to={`/plan/review/${id}`}>
            Revisar
          </SmallButton>
        </Grid>
      )}
    </Grid>
  );
}

export default function ActionListTypeProvider(props) {
  const { rows, setRows } = props;
  return (
    <DataTypeProvider
      formatterComponent={(props) => (
        <ActionFormatter {...props} rows={rows} setRows={setRows} />
      )}
      {...props}
    />
  );
}
