import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { green, blue, red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import ConnectedBasicBusinessDataForm from '../containers/ConnectedBasicBusinessDataForm';
import SinglePotentialChartContainer from './SinglePotentialChartContainer';
import PaperHeaderContainer from './PaperHeaderContainer';
import {
  getPrevColoringPotentials,
  getPrevRetailPotentials,
  getPrevServicePotentials,
} from '../state/selectors/potentials';
import SubViewMainContentArea from './SubViewMainContentArea';

const useStyles = makeStyles(theme => ({
  gridRoot: {
    padding: theme.spacing(0, 3),
  },
  paper: {
    padding: theme.spacing(2),
  },
  mainTitle: {
    marginBottom: theme.spacing(3),
  },
  columnTitle: {
    marginBottom: theme.spacing(2),
  },
}));

// TODO: Rename to YourBusinessSubView
function InitialBusinessDiagnosticView({
  prevRetailSales,
  retailPotentialInUnits,
  retailPotentialMet,
  prevServiceSales,
  servicePotentialInUnits,
  servicePotentialMet,
  prevColoringSales,
  isColoringActive,
  coloringPotentialInUnits,
  coloringPotentialMet,
}) {
  const classes = useStyles();

  return (
    <SubViewMainContentArea>
      <Grid
        className={classes.gridRoot}
        container
        spacing={3}
        justify="space-between"
      >
        <Grid item sm={3}>
          <PaperHeaderContainer
            title="Diágnosticos - KPIs"
            minHeight={435}
            isFlat
          >
            <ConnectedBasicBusinessDataForm />
          </PaperHeaderContainer>
        </Grid>
        <Grid item sm={3}>
          <PaperHeaderContainer title="Reventa" minHeight={435} isFlat>
            <SinglePotentialChartContainer
              name="productos"
              actualSalesUnits={prevRetailSales}
              potentialSalesUnits={retailPotentialInUnits}
              potentialMetPercentage={retailPotentialMet}
              fill={green[200]}
            />
          </PaperHeaderContainer>
        </Grid>
        <Grid item sm={3}>
          <PaperHeaderContainer title="Tratamientos" minHeight={435} isFlat>
            <SinglePotentialChartContainer
              name="tratamientos"
              actualSalesUnits={prevServiceSales}
              potentialSalesUnits={servicePotentialInUnits}
              potentialMetPercentage={servicePotentialMet}
              fill={blue[200]}
            />
          </PaperHeaderContainer>
        </Grid>
        {isColoringActive && (
          <Grid item sm={3}>
            <PaperHeaderContainer
              title="Color + Balayage"
              minHeight={435}
              isFlat
            >
              <SinglePotentialChartContainer
                name="coloraciones"
                actualSalesUnits={prevColoringSales}
                potentialSalesUnits={coloringPotentialInUnits}
                potentialMetPercentage={coloringPotentialMet}
                fill={red[200]}
              />
            </PaperHeaderContainer>
          </Grid>
        )}
      </Grid>
    </SubViewMainContentArea>
  );
}

function mapStateToProps(state) {
  const {
    prevServiceSales,
    prevRetailSales,
    isColoringActive,
    prevColoringSales,
  } = state.businessPlan;

  const retailPotential = getPrevRetailPotentials(state);
  const servicePotential = getPrevServicePotentials(state);
  const coloringPotential = getPrevColoringPotentials(state);

  return {
    prevRetailSales,
    retailPotentialInUnits: retailPotential.inUnits,
    retailPotentialMet: retailPotential.metInPercentage,
    prevServiceSales,
    servicePotentialInUnits: servicePotential.inUnits,
    servicePotentialMet: servicePotential.metInPercentage,
    isColoringActive,
    prevColoringSales,
    coloringPotentialInUnits: coloringPotential.inUnits,
    coloringPotentialMet: coloringPotential.metInPercentage,
  };
}

const ConnectedInitialBusinessDiagnosticView = connect(mapStateToProps)(
  InitialBusinessDiagnosticView,
);

export default ConnectedInitialBusinessDiagnosticView;
