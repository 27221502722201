import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import withAuthorization from '../withAuthorization';
import withTopBar from '../withTopBar';
import SubViewContainer from '../SubViewContainer';
import AdminCustomerManagementTable from './AdminCustomerManagementTable';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CustomerCsvUploadModal from './CustomerCsvUploadModal';
import {
  getCustomerListByBrandScopes,
  uploadCustomerCsv,
  addCustomer,
  editCustomer,
  removeCustomer,
} from '../../state/actions/customers';

const useStyles = makeStyles(theme => ({
  topControls: {
    display: 'flex',
    padding: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(4),
    },
    borderBottom: 0,
  },
  loginContextContainer: {
    '& > *': {
      fontSize: '0.85rem',
      color: theme.palette.text.secondary,
    },
    flex: 1,
  },
}));

const AdminCustomerManagementPage = ({
  authUser,
  getCustomerListByBrandScopes,
  uploadCustomerCsv,
  addCustomer,
}) => {
  // TODO: Implement this notion on the data layer
  // Superadmins can change the entire program
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');
  // TODO: Change default to false
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [customerList, setCustomerList] = useState([]);

  async function fetchAndSetCustomerList() {
    const updatedList = await getCustomerListByBrandScopes(
      authUser.brandScopes,
    );
    setCustomerList(updatedList);
  }

  useEffect(() => {
    fetchAndSetCustomerList();
  }, []);

  function handleFileUpload(event) {
    if (event.target.files.length === 0) {
      return 0;
    }
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async ev => {
      const data = { fileData: ev.target.result, mode: 'add' };
      await uploadCustomerCsv(data);
      setIsUploadModalOpen(false);
      await fetchAndSetCustomerList();
    };
    reader.readAsText(file);
  }

  async function handleCommitChanges({ added, changed, deleted }) {
    let changedRows;
    if (added) {
      try {
        await addCustomer(added[0]);
      } catch (err) {
        console.error('ERROR: ', err);
        return;
      }
      const startingAddedId =
        customerList.length > 0
          ? customerList[customerList.length - 1].docId + 1
          : 0;
      changedRows = [
        ...added.map((row, index) => ({
          docId: startingAddedId + index,
          ...row,
        })),
        ...customerList,
      ];
    }
    if (changed) {
      try {
        editCustomer(changed[0]);
      } catch (err) {
        return;
      }
      changedRows = customerList.map(row =>
        changed[row.docId] ? { ...row, ...changed[row.docId] } : row,
      );
    }
    if (deleted) {
      try {
        removeCustomer(deleted[0]);
      } catch (err) {
        return;
      }
      const deletedSet = new Set(deleted);
      changedRows = customerList.filter(row => !deletedSet.has(row.docId));
    }
    setCustomerList(changedRows);
  }

  return (
    <>
      <SubViewContainer title="Gestión de clientes">
        <CustomerCsvUploadModal
          isOpen={isUploadModalOpen}
          handleFileUpload={handleFileUpload}
          handleClose={() => setIsUploadModalOpen(false)}
        />
        <Paper className={classes.topControls} elevation={0} variant="outlined">
          <div className={classes.loginContextContainer}>
            <Typography variant="body2">
              Logeado como: {authUser.email}
            </Typography>
            <Typography variant="body2">
              Organización: {authUser.brandScopes.join(';')}
            </Typography>
          </div>
          <div className={classes.searchContainer}>
            <Input
              id="customer-management-table-searchbox"
              placeholder="Buscar cliente..."
              value={searchValue}
              onChange={ev => setSearchValue(ev.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </div>
          <div>
            <Button
              startIcon={<CloudUploadIcon />}
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => setIsUploadModalOpen(true)}
            >
              Subir CSV
            </Button>
          </div>
        </Paper>
        <Paper elevation={0} variant="outlined" square>
          {customerList.length > 0 && (
            <AdminCustomerManagementTable
              rows={customerList}
              searchValue={searchValue}
              onSearchChange={setSearchValue}
              onCommitChanges={handleCommitChanges}
            />
          )}
        </Paper>
      </SubViewContainer>
    </>
  );
};

const authorizationCondition = authUser =>
  authUser.isSuperAdmin &&
  authUser.brandScopes &&
  authUser.brandScopes.length > 0;

const mapStateToProps = state => ({
  authUser: state.session.authUser,
});

const mapDispatchToProps = {
  getCustomerListByBrandScopes,
  uploadCustomerCsv,
  addCustomer,
};

export default compose(
  withAuthorization(authorizationCondition),
  withTopBar(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AdminCustomerManagementPage);
