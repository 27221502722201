import _ from 'lodash';
import { ACTION_TYPE_NAMES } from '../constants';

export function serializeBusinessPlan(planData) {
  // Remove unwanted keys (summaryRows)
  // Transform supportingActions to animations and businessActions
  const picked = _.pick(planData, [
    'customerId',
    'customerName',
    'nextSalesPeriod',
    'numCollabs',
    'prevRetailSales',
    'prevServiceSales',
    'prevColoringSales',
    'prevSalesPeriod',
    'repEmail',
    'repId',
    'retailPotential',
    'servicePotential',
    'coloringPotential',
    'isColoringActive',
    'prevColoringSales',
    'qualitativeObjectives',
  ]);

  if (picked.isColoringActive === false) {
    picked.prevColoringSales = null;
  }

  const supportingActions = serializeSupportingActions(
    planData.supportingActions,
  );

  return {
    ...picked,
    supportingActions,
    targets: planData.salesTargets,
  };
}

function serializeSupportingActions(supportingActions) {
  let serialized = {};

  for (const action of Object.values(supportingActions)) {
    if (action.isSelected) {
      const { id } = action;
      if (action.actionType === ACTION_TYPE_NAMES.coaching) {
        serialized[id] = {
          id: id,
          type: action.actionType,
        };
      } else {
        // action.type === animation
        serialized[id] = {
          id: id,
          type: action.actionType,
          goodies: serializeGoodies(action.goodies),
        };
      }
    }
  }

  return serialized;
}

function serializeGoodies(linkedAnimationGoodies) {
  const serialized = {};

  for (let goodieId in linkedAnimationGoodies) {
    const goodie = linkedAnimationGoodies[goodieId];
    serialized[goodieId] = _.pick(goodie, ['id', 'quantity']);
  }

  return serialized;
}
