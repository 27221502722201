export const getPrevSalesPeriod = state => state.businessPlan.prevSalesPeriod;
export const getNextSalesPeriod = state => state.businessPlan.nextSalesPeriod;
export const getNumCollabs = state => state.businessPlan.numCollabs;
export const getPrevServiceSales = state => state.businessPlan.prevServiceSales;
export const getPrevRetailSales = state => state.businessPlan.prevRetailSales;
export const getPrevColoringSales = state =>
  state.businessPlan.prevColoringSales;
export const getNextServiceSales = state =>
  state.businessPlan.summaryRows[0].sellOut;
export const getNextRetailSales = state =>
  state.businessPlan.summaryRows[1].sellOut;
export const getNextColoringSales = state =>
  state.businessPlan.summaryRows[2].sellOut;
