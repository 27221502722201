import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import NextPeriodInput from './SellOutTable/NextPeriodInput';
import { Typography } from '@material-ui/core';
import SellOutTable from './SellOutTable/SellOutTable';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import PriceReferenceTable from './PriceReferenceTable';
import Popper from '@material-ui/core/Popper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { toggleModal } from "../state/actions/ui";

const useStyles = makeStyles(theme => ({
  periodContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  tableContainer: {
    padding: theme.spacing(0, 2),
    overflowX: 'auto',
  },
  popperContainer: {
    marginTop: theme.spacing(1),
    zIndex: 9000,
  },
}));

function SellOutTableContainer(props) {
  const { isAbridged, isEditable, nextSalesPeriod, toggleModal } = props;
  const classes = useStyles();
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);

  const isPopperOpen = Boolean(popperAnchorEl);
  const popperId = isPopperOpen ? 'price-ref-popopver' : undefined;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const togglePopper = event => {
    const target = isPopperOpen ? null : event.currentTarget;
    setPopperAnchorEl(target);
  };

  const periodComponent = isEditable ? (
    <NextPeriodInput />
  ) : (
    <Typography variant="body2" color="textSecondary">
      Semanas de venta: {nextSalesPeriod}
    </Typography>
  );

  // This component is the full table rendered in modal on mobile
  // TODO: This recursive definition is fairly confusing; simplify
  const tableComponent = (
    <Container>
      <Paper
        className={classes.tableContainer}
        elevation={0}
        variant="outlined"
        square
      >
        <SellOutTableContainer
          nextSalesPeriod={nextSalesPeriod}
          isEditable={false}
          isAbridged={false}
        />
      </Paper>
    </Container>
  );

  return (
    <div>
      <Grid
        className={classes.periodContainer}
        direction={isSmall ? 'column' : 'row'}
        justify="space-between"
        container
      >
        <Grid item>{periodComponent}</Grid>
        {isAbridged && (
          <Grid item>
            <Link
              href="#"
              onClick={e => {
                e.preventDefault();
                toggleModal(tableComponent);
              }}
            >
              Ver detalle
            </Link>
          </Grid>
        )}
        {!isAbridged && (
          <Grid item>
            <Link
              href="#"
              onClick={e => {
                e.preventDefault();
                togglePopper(e);
              }}
            >
              {isPopperOpen ? 'Ocultar ' : 'Mostrar '} precios de referencia
            </Link>
            <Popper
              id={popperId}
              className={classes.popperContainer}
              open={isPopperOpen}
              anchorEl={popperAnchorEl}
              placement="bottom-end"
            >
              <PriceReferenceTable />
            </Popper>
          </Grid>
        )}
      </Grid>
      <SellOutTable isAbridged={isAbridged} isEditable={isEditable} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    nextSalesPeriod: state.businessPlan.nextSalesPeriod,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleModal: modalContent => dispatch(toggleModal(modalContent)),
  };
}

const ConnectedSellOutTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SellOutTableContainer);

export default ConnectedSellOutTableContainer;
