import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  lighten,
} from '@material-ui/core';
import pink from '@material-ui/core/colors/pink';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    backgroundColor: lighten(pink[50], 0.5),
    padding: theme.spacing(0, 1),
  },
  tableCell: {
    padding: theme.spacing(0.66, 0.66),
  },
  tableHeaderCell: {
    fontSize: '0.64rem',
    padding: theme.spacing(0.66, 0.66),
  },
  tableText: {
    fontSize: '0.74rem',
  },
  priceText: {
    fontSize: '0.82rem',
    fontFamily: 'monospace',
  },
}));

function generateRow(productName, avgRetailPrice, avgRetailCost) {
  return { productName, avgRetailPrice, avgRetailCost };
}

const rows = [
  generateRow('Color básico', '25,00€', '0€'),
  generateRow('Tono sobre tono', '20€', '0€'),
  generateRow('Decoloración', '50,00€', '0€'),
  generateRow('Balayage', '100,00€', '0€'),
  generateRow('Tratamiento - Acondicionador', '3,00€', '0€'),
  generateRow('Tratamiento - Mascarillas', '10,00€', '0€'),
  generateRow('Tratamiento - Monodosis', '14,00€', '0€'),
];

export default function PriceReferenceTable(props) {
  const classes = useStyles();
  return (
    <TableContainer
      className={classes.tableContainer}
      component={Paper}
      elevation={2}
      square
    >
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell}>Producto</TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">
              PVPR medio
            </TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">
              PCAT medio
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell
                className={classes.tableCell}
                component="th"
                scope="row"
              >
                <Typography
                  className={classes.tableText}
                  color="textSecondary"
                  variant="body2"
                >
                  {row.productName}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography
                  className={classes.priceText}
                  color="textSecondary"
                  variant="body2"
                >
                  {row.avgRetailPrice}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography
                  className={classes.priceText}
                  color="textSecondary"
                  variant="body2"
                >
                  {row.avgRetailCost}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
