import React from 'react';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircleOutlined';
import RemoveIcon from '@material-ui/icons/Remove';

/**
 * Customizes the grid action column to add icons
 */
const CustomCommandComponent = (props) => {
  if (props.id === 'add') {
    return (
      <IconButton onClick={props.onExecute}>
        <AddCircleIcon />
      </IconButton>
    );
  } else if (props.id === 'delete') {
    return (
      <IconButton onClick={props.onExecute}>
        <RemoveIcon />
      </IconButton>
    );
  } else {
    return <TableEditColumn.Command {...props} />;
  }
};

export default CustomCommandComponent;
