import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import InputWithStepButtons from './InputWithStepButtons';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  goodieListContainer: {
    margin: theme.spacing(3, 0, 1),
  },
  cardImage: {
    height: 128,
    backgroundSize: 'contain',
  },
  cardContent: {
    paddingBottom: 0,
    marginBottom: theme.spacing(1),
  },
  cardBottom: {
    backgroundColor: 'rgb(253, 253, 253)',
  },
  cardMediaContainer: {
    padding: theme.spacing(0.5, 0, 1),
  },
}));

function getQuantityComponent(isEditable, quantity, goodieId, animationId) {
  if (!isEditable) {
    return (
      <Typography variant="body2" color="textSecondary">
        Cantidad: {quantity}
      </Typography>
    );
  }

  return <InputWithStepButtons goodieId={goodieId} animationId={animationId} />;
}

export const GoodieList = props => {
  const { goodies, isEditable, animationId } = props;

  const classes = useStyles();

  // Guard against null / undefined
  const goodieList = Object.values(goodies || {});

  if (goodieList.length < 1) {
    return (
      <Typography variant="body1">No hay elementos disponibles</Typography>
    );
  }

  return (
    <Grid className={classes.goodieListContainer} container spacing={2}>
      {goodieList.map(goodie => {
        // Do not render if we are in view mode and the quantity is zero
        if (goodie.isQuantityBased && goodie.quantity === 0 && !isEditable) {
          return null;
        }

        return (
          <Grid className={classes.gridItem} key={goodie.id} item xs={6} md={4}>
            <Card variant="outlined" square>
              <div className={classes.cardMediaContainer}>
                <CardMedia
                  className={classes.cardImage}
                  image={goodie.previewPicture + '?w=200'}
                />
              </div>
              <Divider />
              <div className={classes.cardBottom}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="body1">{goodie.title}</Typography>
                </CardContent>
                <CardActions>
                  {goodie.isQuantityBased &&
                    getQuantityComponent(
                      isEditable,
                      goodie.quantity,
                      goodie.id,
                      animationId,
                    )}
                  {goodie.downloadables.length > 0 && (
                    <Button
                      href={goodie.downloadables[0].file}
                      target="_blank"
                      size="small"
                      variant="contained"
                      color="secondary"
                    >
                      Descárgalo
                    </Button>
                  )}
                </CardActions>
              </div>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
