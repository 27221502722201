import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  modalBody: {
    position: 'absolute',
    width: '1200px',
    maxWidth: '100vw',
    overflowY: 'auto',
    height: '100vh',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: theme.palette.background.default,
  },
  appBarTitle: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: 'rgb(33,33,33)',
    marginBottom: theme.spacing(4),
  },
  closeButton: {
    // TODO: Investigate
    // If we remove the important property, the button text is black
    // This doesn't seem to happen in the production build
    // Why the discrepancy?
    color: 'white !important',
  },
  modalContentWrapper: {
    // Hack to create some spacing when bottom context bar is open on iOS mobile
    // (ie, the bar that contains tabs, etc.)
    // This happens when it was present on the modal parent; to reproduce:
    // Scroll up on parent, then open modal
    // Need to investigate more permanent fix
    paddingBottom: theme.spacing(16),
    backgroundColor: theme.palette.background.paper,
  },
}));

function ModalContainer(props) {
  const classes = useStyles();
  const { onClickClose, modalComponent } = props;

  return (
    <div className={classes.modalBody}>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar variant="dense">
          <Typography variant="subtitle1" className={classes.appBarTitle}>
            Vista detallada
          </Typography>
          <Button
            onClick={onClickClose}
            className={classes.closeButton}
            endIcon={<CloseIcon />}
          >
            Cerrar
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.modalContentWrapper}>{modalComponent}</div>
    </div>
  );
}

function mapStateToProps(state) {
  return { modalComponent: state.ui.modalData };
}

const ConnectedModalContainer = connect(mapStateToProps)(ModalContainer);

export default ConnectedModalContainer;
