import _ from 'lodash';
import {
  COLORING_POTENTIAL,
  PRODUCT_TYPES,
  RETAIL_POTENTIAL,
  SERVICE_POTENTIAL,
} from '../../constants';
import { presetRows } from '../../components/SellOutTable/presets';
import {
  APPEND_SUPPORTING_ACTIONS,
  LOAD_SUPPORTING_ACTIONS,
  REMOVE_SUPPORTING_ACTIONS,
} from '../actions/supporting-actions';
import {
  CHANGE_ANIMATION_GOODIE_QTY,
  LOAD_BUSINESS_PLAN,
  SET_CUSTOMER_ID,
  SET_CUSTOMER_NAME,
  SET_NEXT_SALES_PERIOD,
  SET_NUM_COLLABS,
  SET_PREV_RETAIL_SALES,
  SET_PREV_SALES_PERIOD,
  SET_PREV_SERVICE_SALES,
  TOGGLE_SUPPORTING_ACTION,
  SET_BUSINESS_MODEL_ROWS,
  SET_PREV_COLORING_SALES,
  SET_IS_COLORING_ACTIVE,
  RESET_BUSINESS_PLAN,
  SET_QUALITATIVE_OBJECTIVES,
} from '../actions/business-plan';
import { generateSummaryRows } from '../../components/SellOutTable/utils';

const defaultBusinessPlanData = {
  planId: undefined,
  customerId: undefined,
  customerName: undefined,
  servicePotential: SERVICE_POTENTIAL,
  retailPotential: RETAIL_POTENTIAL,
  coloringPotential: COLORING_POTENTIAL,
  prevSalesPeriod: 4,
  numCollabs: 1,
  prevServiceSales: undefined,
  prevRetailSales: undefined,
  isColoringActive: true,
  prevColoringSales: undefined,
  nextSalesPeriod: 4,
  supportingActions: undefined,
  salesTargets: presetRows,
  // TODO: Move into selector
  summaryRows: generateSummaryRows(presetRows, 4, 1),
  qualitativeObjectives: '',
};

export default function businessPlan(state = defaultBusinessPlanData, action) {
  switch (action.type) {
    case LOAD_BUSINESS_PLAN: {
      const businessPlan = action.payload;
      const {
        salesTargets,
        nextSalesPeriod,
        numCollabs,
        isColoringActive,
      } = businessPlan;
      return {
        ...businessPlan,
        summaryRows: generateSummaryRows(
          salesTargets,
          nextSalesPeriod,
          numCollabs,
          isColoringActive,
        ),
      };
    }

    case SET_CUSTOMER_ID:
      return { ...state, customerId: action.payload };

    case SET_CUSTOMER_NAME:
      return { ...state, customerName: action.payload };

    case SET_PREV_SALES_PERIOD:
      return { ...state, prevSalesPeriod: action.payload };

    case SET_PREV_RETAIL_SALES:
      return { ...state, prevRetailSales: action.payload };

    case SET_PREV_SERVICE_SALES:
      return { ...state, prevServiceSales: action.payload };

    case SET_IS_COLORING_ACTIVE: {
      const isColoringActive = action.payload;
      let prevColoringSales = state.prevColoringSales;
      let salesTargets = [...state.salesTargets];

      if (!isColoringActive) {
        prevColoringSales = undefined;
        salesTargets = salesTargets.filter(
          row => row.type !== PRODUCT_TYPES.coloring,
        );
      }

      return {
        ...state,
        salesTargets,
        isColoringActive,
        prevColoringSales,
        summaryRows: generateSummaryRows(
          state.salesTargets,
          state.nextSalesPeriod,
          state.numCollabs,
          isColoringActive,
        ),
      };
    }

    case SET_PREV_COLORING_SALES:
      return { ...state, prevColoringSales: action.payload };

    case SET_NUM_COLLABS:
      return { ...state, numCollabs: action.payload };

    case SET_NEXT_SALES_PERIOD:
      return { ...state, nextSalesPeriod: action.payload };

    case LOAD_SUPPORTING_ACTIONS:
      return { ...state, supportingActions: action.payload };

    case APPEND_SUPPORTING_ACTIONS:
      return {
        ...state,
        supportingActions: { ...state.supportingActions, ...action.payload },
      };

    case REMOVE_SUPPORTING_ACTIONS: {
      const { supportingActions } = state;
      const { brand, productTypesToKeep } = action.payload;
      const filtered = _.pickBy(supportingActions, (value, key) => {
        return (
          value.isSelected ||
          !(
            value.brand === brand &&
            _.intersection(productTypesToKeep, value.productType).length === 0
          )
        );
      });
      return {
        ...state,
        supportingActions: filtered,
      };
    }

    case TOGGLE_SUPPORTING_ACTION: {
      const actionId = action.payload;
      const supportingActions = { ...state.supportingActions };
      const prevState = supportingActions[actionId].isSelected;
      supportingActions[actionId].isSelected = !prevState;
      return { ...state, supportingActions };
    }

    case CHANGE_ANIMATION_GOODIE_QTY: {
      const { actionId, goodieId, quantity } = action.payload;
      const supportingActions = { ...state.supportingActions };
      supportingActions[actionId].goodies[goodieId].quantity = quantity;
      return { ...state, supportingActions };
    }

    case SET_BUSINESS_MODEL_ROWS: {
      const newRows = action.payload;
      const newSummary = generateSummaryRows(
        newRows,
        state.nextSalesPeriod,
        state.numCollabs,
        state.isColoringActive,
      );
      return {
        ...state,
        salesTargets: newRows,
        summaryRows: newSummary,
      };
    }

    case SET_QUALITATIVE_OBJECTIVES: {
      return {
        ...state,
        qualitativeObjectives: action.payload,
      };
    }

    case RESET_BUSINESS_PLAN:
      return defaultBusinessPlanData;

    default:
      return state;
  }
}
