import {
  LOAD_PLAN_REVISION,
  SET_REVISION_ROWS,
  SET_COMMENT,
} from '../actions/plan-revision';
import { generateSummaryRows } from '../../components/SellOutTable/utils';

const defaultPlanRevisionData = {
  planId: undefined,
  customerName: undefined,
  activationDate: undefined,
  finishDate: undefined,
  numCollabs: undefined,
  nextSalesPeriod: undefined,
  revisionRows: [],
  summaryRows: [],
  comment: '',
};

// TODO: Generate summaryRows with selector
export default function planRevision(state = defaultPlanRevisionData, action) {
  switch (action.type) {
    case LOAD_PLAN_REVISION: {
      console.log('LOAD_PLAN_REVISION payload: ', action.payload);
      const { revisionRows, numCollabs, nextSalesPeriod } = action.payload;
      return {
        ...state,
        ...action.payload,
        summaryRows: generateSummaryRows(
          revisionRows,
          nextSalesPeriod,
          numCollabs,
        ),
      };
    }

    case SET_REVISION_ROWS: {
      const { nextSalesPeriod, numCollabs } = state;
      return {
        ...state,
        revisionRows: action.payload,
        summaryRows: generateSummaryRows(
          action.payload,
          nextSalesPeriod,
          numCollabs,
        ),
      };
    }

    case SET_COMMENT:
      return { ...state, comment: action.payload };

    default:
      return state;
  }
}
