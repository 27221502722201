import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import ListItem from '@material-ui/core/ListItem/index';
import ListItemText from '@material-ui/core/ListItemText/index';
import ListItemAvatar from '@material-ui/core/ListItemAvatar/index';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/index';
import Avatar from '@material-ui/core/Avatar/index';
import Checkbox from '@material-ui/core/Checkbox/index';
import Typography from '@material-ui/core/Typography/index';
import SupportingActionInfoChipList from './SupportingActionInfoChipList';
import { SUPPORTING_ACTION_CHIP_LABELS } from '../../constants';

const useStyles = makeStyles(theme => ({
  headlineText: {
    fontSize: '0.9rem',
  },
  subHeadlineText: {
    fontSize: '0.8rem',
    marginBottom: theme.spacing(0.25),
  },
}));

function getChipListLabels(division, isCurrent, isEditable) {
  if (!isCurrent && isEditable) {
    return [...division, SUPPORTING_ACTION_CHIP_LABELS.archived];
  }
  return division;
}

export const SupportingActionListItem = ({
  imgUrl,
  primaryText,
  secondaryText,
  isSelected,
  onToggle,
  isEditable,
  onClickPrimary,
  division,
  isCurrent,
}) => {
  const classes = useStyles();

  return (
    <ListItem button alignItems="flex-start" onClick={onClickPrimary}>
      <ListItemAvatar>
        <Avatar src={imgUrl + '?w=80'} variant="square" />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography className={classes.headlineText} variant="body1">
            {primaryText}
          </Typography>
        }
        secondary={
          <>
            <Typography
              className={classes.subHeadlineText}
              variant="body2"
              color="textSecondary"
            >
              {secondaryText}
            </Typography>
            <SupportingActionInfoChipList
              labelList={getChipListLabels(division, isCurrent, isEditable)}
              chipClassName={classes.chip}
            />
          </>
        }
        disableTypography
      />
      {isEditable && (
        <ListItemSecondaryAction>
          <Checkbox edge="end" checked={isSelected} onChange={onToggle} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
