import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import LoginPage from './components/LoginPage';
import PlanPage from './components/PlanPage';
import PlanHistoryPage from './components/PlanHistoryPage';
import HomePage from './components/HomePage';
import CustomerPage from './components/CustomerPage';
import PlanEditor from './components/PlanEditor';
import NotFoundPage from './components/NotFoundPage';
import PlanRevisionPage from './components/PlanRevisionPage';
import withAuthentication from './components/withAuthentication';
import AdminLoginPage from './components/admin/AdminLoginPage';
import AdminDashboardPage from './components/admin/AdminDashboardPage';
import AdminCustomerManagementPage from './components/admin/AdminCustomerManagementPage';

function App(props) {
  // TODO: Consolidate PlanEditor and PlanPage
  // Render conditionally based on props / planId
  return (
    <Router>
      <Switch>
        <Route path="/customer/:customerId" component={CustomerPage} />
        <Route
          path="/plan/edit/:planId"
          render={props => <PlanEditor {...props} mode="edit" />}
        />
        <Route
          path="/plan/view/:planId"
          render={props => <PlanEditor {...props} mode="view" />}
        />
        <Route
          path="/plan/shared/:planId"
          render={props => <PlanEditor {...props} mode="shared" />}
        />
        <Route path="/plan/review/:planId" component={PlanRevisionPage} />
        <Route path="/plan" exact component={PlanPage} />
        <Route path="/history" component={PlanHistoryPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/dashboard" component={AdminDashboardPage} />
        <Route
          path="/manage-customers"
          component={AdminCustomerManagementPage}
        />
        <Route path="/admin" component={AdminLoginPage} />
        <Route path="/" exact component={HomePage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default withAuthentication(App);
