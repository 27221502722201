import React, { useState } from 'react';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid as DxGrid,
  Table,
  TableHeaderRow,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui';
import { PRODUCT_TYPES } from '../../constants';
import NumberTypeProvider, {
  CurrencyTypeProvider,
} from '../SellOutTable/NumberTypeProvider';
import BooleanTypeProvider from '../SellOutTable/ProductTypeProvider';
import { defaultColumnExtensions, generateColumns } from './config';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import lightBlue from '@material-ui/core/colors/lightBlue';

const COMMAND_COL_WIDTH = 50;

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    '& th': {
      padding: 6,
      fontSize: '0.74rem',
      color: theme.palette.text.secondary,
    },
    '& td': {
      padding: '6px 6px',
      fontSize: '0.74rem',
    },
    '& button': {
      padding: 0,
    },
    '& input': {
      fontSize: '0.74rem',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem',
    },
  },
}));

function isColumnNonEditable(columnName) {
  return (
    columnName === 'sellOut' ||
    columnName === 'income' ||
    columnName === 'profit' ||
    columnName === 'totalCost'
  );
}

const CustomCell = ({ column, style, onClick, isSummary, ...restProps }) => {
  let additionalStyle = { ...style };
  if (isColumnNonEditable(column.name)) {
    additionalStyle = { backgroundColor: lighten(grey[50], 0.3) };
  }
  if (isSummary) {
    additionalStyle = { backgroundColor: lighten(lightBlue[50], 0.85) };
  }
  if (column.name === 'profit') {
    additionalStyle.fontWeight = 700;
  }
  return (
    <Table.Cell
      {...restProps}
      style={additionalStyle}
      tabIndex={0}
      onClick={onClick}
      // Assigning onClick behavior to onFocus allows switching with
      // tab and entering edit mode on the next cell
      onFocus={onClick}
    />
  );
};

const CustomSummaryCell = (props) => {
  return <CustomCell {...props} isSummary />;
};

function TableComponent({ ...restProps }) {
  const classes = useStyles();
  return <Table.Table className={classes.tableRoot} {...restProps} />;
}

function CustomInlineEditingCell({ column, ...restProps }) {
  const isEditable = !isColumnNonEditable(column.name);

  return (
    <TableInlineCellEditing.Cell
      {...restProps}
      column={column}
      editingEnabled={isEditable}
    />
  );
}

const getRowId = (row) => row.id;

const isSummaryColumn = (colName) => {
  return colName === 'product' || colName === 'type' || colName === 'sellOut';
};

function PlanRevisionTable(props) {
  const classes = useStyles();
  const {
    numCollabs,
    rows,
    setRows,
    summaryRows,
    isEditable,
    nextSalesPeriod,
    isAbridged,
  } = props;

  // Table columns should be kept as state
  // It's in the docs, plus what if we wanted to add a column?
  // https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/-accessors/
  // Left it like this b/c I couldn't get it to re-render otherwise
  let columns = generateColumns(numCollabs, nextSalesPeriod);
  let [columnExtensions] = useState(defaultColumnExtensions);

  if (isAbridged) {
    columns = columns.filter((col) => isSummaryColumn(col.name));
  }

  // TODO: Abstract this into its own config
  const [booleanColumns] = useState(['type']);
  const [numberColumns] = useState(['realSales']);

  const [editingCells, setEditingCells] = useState([]);

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId =
        rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
      setEditingCells([
        { rowId: startingAddedId, columnName: columns[0].name },
      ]);
    }
    if (changed) {
      changedRows = rows.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row,
      );
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter((row) => !deletedSet.has(row.id));
    }
    setRows(changedRows);
  };

  let summaryColumns = [
    ...columns.map((column) => ({ name: column.name, title: column.title })),
  ];

  return (
    <div>
      <DxGrid rows={rows} columns={columns} getRowId={getRowId}>
        <NumberTypeProvider
          for={numberColumns}
          extensions={{ setEditingCells, rows, setRows }}
        />
        <BooleanTypeProvider
          for={booleanColumns}
          extensions={{
            commitChanges,
            setEditingCells,
            columns,
            rows,
            setRows,
            isAbridged,
          }}
        />
        <EditingState
          onCommitChanges={commitChanges}
          editingCells={editingCells}
          onEditingCellsChange={setEditingCells}
          addedRows={[]}
        />
        <Table
          tableComponent={TableComponent}
          columnExtensions={columnExtensions}
          cellComponent={CustomCell}
          messages={{ noData: 'No hay datos' }}
        />
        <TableHeaderRow />
        <TableInlineCellEditing
          selectTextOnEditStart
          cellComponent={CustomInlineEditingCell}
          columnExtensions={columnExtensions}
        />
      </DxGrid>
      <DxGrid rows={summaryRows} columns={summaryColumns}>
        <BooleanTypeProvider for={booleanColumns} extensions={{ isAbridged }} />
        <NumberTypeProvider for={numberColumns} />
        <Table
          tableComponent={TableComponent}
          columnExtensions={columnExtensions}
          cellComponent={CustomSummaryCell}
        />
      </DxGrid>
    </div>
  );
}

export default PlanRevisionTable;
