import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/HomeSharp';
import ListIcon from '@material-ui/icons/ListSharp';
import LogoutIcon from '@material-ui/icons/ExitToAppSharp';
import ChartIcon from '@material-ui/icons/EqualizerSharp';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FirebaseContext from '../../firebase/context';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  listRoot: {
    width: 300,
    paddingTop: 0,
  },
  drawerPaper: {
    backgroundColor: 'rgb(5, 5, 5)',
  },
  toolbarSpacing: {
    minHeight: 48,
  },
  listItemText: {
    '& span': {
      fontWeight: '300 !important',
    },
  },
}));

function withDarkTheme(Component) {
  return function(props) {
    return (
      <ThemeProvider
        theme={theme =>
          createMuiTheme({
            ...theme,
            palette: {
              type: 'dark',
            },
          })
        }
      >
        <Component {...props} />
      </ThemeProvider>
    );
  };
}

function NavMenu(props) {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const { isMenuOpen, toggleMenu } = props;

  const authUser = useSelector(state => state.session.authUser);

  async function handleLogout() {
    try {
      await firebase.logout();
    } catch (e) {
      toast.error('No se pudo desconectar. Inténtalo más tarde.');
    }
  }

  return (
    <Drawer
      anchor="right"
      open={isMenuOpen}
      onClose={() => toggleMenu(false)}
      PaperProps={{ className: classes.drawerPaper }}
    >
      <div className={classes.toolbarSpacing} />
      <List className={classes.listRoot}>
        <ListItem button component={Link} key="Principal" to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary="Principal" />
        </ListItem>
        <ListItem button component={Link} key="Histórico" to="/history">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary="Histórico" />
        </ListItem>
        {(authUser && authUser.isAdmin) ||
          (authUser.isSuperAdmin && (
            <ListItem button component={Link} key="Métricas" to="/dashboard">
              <ListItemIcon>
                <ChartIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Métricas"
              />
            </ListItem>
          ))}
        {authUser && authUser.isSuperAdmin && (
          <ListItem
            button
            component={Link}
            key="Gestión Clientes"
            to="/manage-customers"
          >
            <ListItemIcon>
              <BusinessCenterIcon />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary="Gestión Clientes"
            />
          </ListItem>
        )}
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            primary="Desconectar"
          />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default withDarkTheme(NavMenu);
