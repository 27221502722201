import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles(theme => ({
  basicDataForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
  },
  textField: {
    maxWidth: 200,
    width: '100%',
  },
  textFieldMb: {
    marginBottom: theme.spacing(3),
  },
  textFieldMbLast: {
    marginBottom: theme.spacing(1.5),
  },
  colorationButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  colorationButtonIcon: {
    fontSize: theme.typography.body1.fontSize,
    marginRight: theme.spacing(0.5),
  },
}));

export default function BusinessBasicDataForm({
  numCollabs,
  onNumCollabsChange,
  prevSalesPeriod,
  onPrevSalesPeriodChange,
  prevServiceSales,
  onPrevServiceSalesChange,
  prevRetailSales,
  onPrevRetailSalesChange,
  prevColoringSales,
  onPrevColoringSalesChange,
  isColoringActive,
  setIsColoringActive,
}) {
  const classes = useStyles();

  return (
    <div>
      <form className={classes.basicDataForm} autoComplete="off">
        <TextField
          className={clsx(classes.textField, classes.textFieldMb)}
          label="Número colaboradores"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={numCollabs}
          onChange={ev => {
            onNumCollabsChange(ev.target.value);
          }}
          size={'small'}
        />
        <TextField
          className={clsx(classes.textField, classes.textFieldMb)}
          label="Semanas desde última visita"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={prevSalesPeriod}
          onChange={ev => onPrevSalesPeriodChange(ev.target.value)}
          helperText={'En semanas'}
          size={'small'}
        />
        <TextField
          className={clsx(classes.textField, classes.textFieldMb)}
          label="Productos vendidos"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={prevRetailSales}
          onChange={ev => {
            onPrevRetailSalesChange(ev.target.value);
          }}
          helperText={`En las ${prevSalesPeriod} semanas anteriores`}
          size={'small'}
        />
        <TextField
          className={clsx(
            classes.textField,
            isColoringActive ? classes.textFieldMb : classes.textFieldMbLast,
          )}
          label="Tratamientos realizados"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={prevServiceSales}
          onChange={ev => {
            onPrevServiceSalesChange(ev.target.value);
          }}
          helperText={`En las ${prevSalesPeriod} semanas anteriores`}
          size={'small'}
        />
        {isColoringActive && (
          <TextField
            className={clsx(classes.textField, classes.textFieldMbLast)}
            label="Nº serv. color + balayage"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={prevColoringSales}
            onChange={ev => {
              onPrevColoringSalesChange(ev.target.value);
            }}
            helperText={`En las ${prevSalesPeriod} semanas anteriores`}
            size={'small'}
          />
        )}
        <Link
          className={classes.colorationButton}
          onClick={() => setIsColoringActive(!isColoringActive)}
        >
          {isColoringActive ? (
            <>
              <RemoveCircleIcon className={classes.colorationButtonIcon} />
              Quitar coloración
            </>
          ) : (
            <>
              <AddCircleIcon className={classes.colorationButtonIcon} />
              Añadir coloración
            </>
          )}
        </Link>
      </form>
    </div>
  );
}
