import logoLoreal from '../media/logo_loreal.png';
import logoKerastase from '../media/logo_kerastase_black.png';
import logoMatrix from '../media/logo_matrix.png';
import logoRedken from '../media/logo_redken.png';
import logoShu from '../media/logo_shu.png';
import logoCommonContents from '../media/logo_common_contents.png';

export const PRODUCT_TYPES = {
  retail: 'r',
  technical: 't',
  coloring: 'c',
};

export const CONTENT_TYPES = {
  animation: 'animation',
  action: 'business-action',
};

export const ACTION_TYPE_NAMES = {
  animation: 'animation',
  coaching: 'coaching',
};

export const PLAN_STATES = {
  inProgress: 'En curso',
  pending: 'Pendiente',
  finalized: 'Finalizado',
};

export const PLAN_META = {
  id: 'id',
  salon: 'salon',
  state: 'state',
  activationDate: 'activationDate',
  finishDate: 'finishDate',
  expiration: 'expiration',
  modifiedAt: 'modifiedAt',
};

export const PRODUCT_TYPES_DISPLAY_NAME = {
  service: 'Servicio',
  retail: 'Reventa',
  color: 'Coloración',
};

export const SUPPORTING_ACTION_CHIP_LABELS = {
  ...PRODUCT_TYPES_DISPLAY_NAME,
  archived: 'Archivada',
};

/**
 * POTENTIAL CONSTANTS
 */
export const SERVICE_POTENTIAL = 10;
export const RETAIL_POTENTIAL = 4;
export const COLORING_POTENTIAL = 14;

/**
 * COLUMN NAMES
 */
export const COLUMN_KEYS = {
  salesWeekCollab: 'salesWeekCollab',
  productType: 'productType',
  sellIn: 'sellIn',
};

/**
 * COLUMN TYPES
 */
export const COLUMN_TYPES_MAP = {
  multipleChoice: [COLUMN_KEYS.productType],
  number: [COLUMN_KEYS.salesWeekCollab, COLUMN_KEYS.sellIn],
};

export const RADIAL_CHART_BG_FILL = '#eee';

export const AUTH_USER_KEY = 'authUser';

export const BRAND_DISPLAY_NAME = {
  loreal: "L'Oréal Professionnel",
  kerastase: 'Kérastase',
  shu: 'Shu Uemura',
  matrix: 'Matrix',
  redken: 'Redken',
  commonContents: 'Contenidos Comunes',
};

export const BRAND_LOGO_MAP = {
  [BRAND_DISPLAY_NAME.loreal]: logoLoreal,
  [BRAND_DISPLAY_NAME.kerastase]: logoKerastase,
  [BRAND_DISPLAY_NAME.matrix]: logoMatrix,
  [BRAND_DISPLAY_NAME.redken]: logoRedken,
  [BRAND_DISPLAY_NAME.shu]: logoShu,
  [BRAND_DISPLAY_NAME.commonContents]: logoCommonContents,
};

export const SUPPORTING_ACTION_FILTERS_LOCALSTORAGE_KEY =
  'supportingActionFilters';
