import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { FILTER_DRAWER_WIDTH } from './SupportingActionView';
import SupportingActionsFilterList from './SupportingActionsFilterList';

const useStyles = makeStyles(theme => ({
  // To implement sticky behavior on drawer we have to hack around MUI defaults
  // Outer drawer now needs border and background color to show properly
  drawer: {
    // navbar height 56px, stepper bar 68px
    minHeight: 'calc(100vh - 56px - 68px)',
    width: FILTER_DRAWER_WIDTH,
    flexShrink: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'rgb(248, 248, 248)',
  },
  // Inner drawer paper needs unsetting height so that sticky works
  drawerPaper: {
    position: 'sticky',
    top: 0,
    width: FILTER_DRAWER_WIDTH,
    height: 'unset',
    backgroundColor: 'rgb(248, 248, 248)',
  },
  drawerControlContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 1),
    justifyContent: 'flex-end',
  },
  isClosed: {
    display: 'none',
  },
}));

export default function SupportingActionFilterDrawer({ isOpen, setIsOpen }) {
  const classes = useStyles();

  return (
    // Transition is set to zero since we hack the inner drawer
    <Drawer
      className={clsx(classes.drawer, { [classes.isClosed]: !isOpen })}
      anchor="left"
      variant="persistent"
      open={isOpen}
      classes={{ paper: classes.drawerPaper }}
      transitionDuration={0}
    >
      <div className={classes.drawerControlContainer}>
        <IconButton onClick={() => setIsOpen(false)}>
          <ClearIcon />
        </IconButton>
      </div>
      <SupportingActionsFilterList />
    </Drawer>
  );
}
