import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export default function SubViewContainer(props) {
  const classes = useStyles();
  const { title, CustomHeaderComponent, children } = props;

  const headerComponent = CustomHeaderComponent ? (
    <CustomHeaderComponent />
  ) : (
    <Typography variant="h4">{title}</Typography>
  );

  return (
    <Container>
      <div className={classes.headerContainer}>{headerComponent}</div>
      {children}
    </Container>
  );
}
