import Joi from 'joi';
import { ACTION_TYPE_NAMES } from '../constants';

// TODO: Parts of validation logic are very flimsy - make rigorous
const contentfulId = Joi.string();

const businessPlanSchema = Joi.object({
  customerId: Joi.string()
    .min(1)
    .required()
    .label('Id de cliente'),
  customerName: Joi.string()
    .min(1)
    .required()
    .label('Nombre de cliente'),
  repEmail: Joi.string()
    .email()
    .required()
    .label('Email de BC'),
  repId: Joi.string()
    .required()
    .label('Id de BC'),
  numCollabs: Joi.number()
    .integer()
    .min(1)
    .required()
    .label('Nº de colaboradores'),
  prevSalesPeriod: Joi.number()
    .integer()
    .min(1)
    .required()
    .label('Período de ventas previo'),
  nextSalesPeriod: Joi.number()
    .integer()
    .min(1)
    .required()
    .label('Período de ventas siguiente'),
  prevRetailSales: Joi.number()
    .integer()
    .positive()
    .allow(0)
    .required()
    .label('Uds. vendidas reventa'),
  prevServiceSales: Joi.number()
    .positive()
    .allow(0)
    .required()
    .label('Servicios vendidos'),
  prevColoringSales: Joi.number()
    .positive()
    .allow(0)
    .allow(null)
    .required()
    .label('Coloraciones vendidas'),
  servicePotential: Joi.number()
    .integer()
    .min(1)
    .required()
    .label('Potencial servicios'),
  retailPotential: Joi.number()
    .integer()
    .min(1)
    .required()
    .label('Potencial reventa'),
  coloringPotential: Joi.number()
    .integer()
    .min(1)
    .required()
    .label('Potencial color'),
  isColoringActive: Joi.boolean()
    .required()
    .label('Contiene coloración'),
  qualitativeObjectives: Joi.string()
    .allow('')
    .label('Objetivos cualitativos'),
  supportingActions: Joi.object()
    .pattern(
      /./,
      Joi.object({
        id: contentfulId,
        type: Joi.string()
          .valid(ACTION_TYPE_NAMES.animation, ACTION_TYPE_NAMES.coaching)
          .required(),
        goodies: Joi.object().pattern(
          /./,
          Joi.object({
            id: contentfulId,
            quantity: Joi.number().allow(null),
          }),
        ),
      }),
    )
    .label('Acciones de negocio')
    .min(1),
  targets: Joi.array()
    .items(
      Joi.object({
        id: Joi.number(),
        product: Joi.string()
          .required()
          .label('Nombre de producto'),
        type: Joi.string()
          .required()
          .label('Tipo de producto'),
        salesWeekCollab: Joi.number()
          .integer()
          .positive()
          .required()
          .label('Venta colab. semana'),
        averageSalesPrice: Joi.number()
          .positive()
          .required()
          .label('PVPR'),
        averageUnitCost: Joi.number()
          .positive()
          .allow(0)
          .required()
          .label('PCAT'),
      }).min(1),
    )
    .label('Tabla objetivos de venta'),
});

const revisionSchema = Joi.object({
  actuals: Joi.array()
    .items(
      Joi.object({
        id: Joi.number(),
        product: Joi.string()
          .required()
          .label('Nombre de producto'),
        type: Joi.string()
          .required()
          .label('Tipo de producto'),
        realSales: Joi.number()
          .min(0)
          .required()
          .label('Ventas (reales)'),
      }),
    )
    .min(1)
    .label('Tabla de ventas'),
  comment: Joi.string()
    .allow('')
    .label('Comentarios'),
}).options({ stripUnknown: true });

export function validatePlan(planData) {
  const { error, value } = businessPlanSchema.validate(planData);

  if (error) {
    throw error;
  }

  return value;
}

export function validateRevision(revisionData) {
  const { error, value } = revisionSchema.validate(revisionData);

  if (error) {
    throw error;
  }

  return value;
}
