import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../firebase';

export default function withAuthorization(authorizationCondition) {
  return function(Component) {
    return function WithAuthorization(props) {
      const firebase = useContext(FirebaseContext);
      const history = useHistory();
      const authUser = useSelector(state => state.session.authUser);

      useEffect(() => {
        firebase.onAuthListener(
          authUser => {
            if (!authorizationCondition(authUser)) {
              history.push('/unauthorized');
            }
          },
          () => history.push('/login'),
        );
      }, []);

      return authorizationCondition(authUser) ? <Component {...props} /> : null;
    };
  };
}
