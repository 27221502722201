import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import Hero from './Hero';
import withTopBar from './withTopBar';
import withAuthorization from './withAuthorization';

const useStyles = makeStyles(theme => ({
  mb: {
    marginBottom: theme.spacing(3),
  },
  greetingContainer: {
    position: 'absolute',
    zIndex: 1,
    right: '10%',
    top: '55%',
    transform: 'translateY(-50%)',
  },
  greeting: {
    fontSize: '7rem',
    lineHeight: '1em',
    color: 'rgb(215, 215, 215)',
  },
  subGreeting: {
    fontSize: '1.35em',
    fontWeight: 300,
    color: 'rgb(215, 215, 215)',
  },
}));

function HomePage(props) {
  const classes = useStyles();

  const authUser = useSelector(state => state.session.authUser);

  return (
    <Hero>
      <Fade in timeout={3000}>
        <div className={classes.greetingContainer}>
          <Typography
            className={classes.greeting}
            variant="h3"
            color="textPrimary"
          >
            {authUser && authUser.firstName ? (
              <div>
                Hola,
                <br /> {authUser.firstName}
              </div>
            ) : (
              'Hola'
            )}
          </Typography>
          <Typography className={classes.subGreeting} variant="body1">
            Busca un cliente para empezar
          </Typography>
        </div>
      </Fade>
    </Hero>
  );
}

const authorizationCondition = authUser => !!authUser;

export default compose(
  withAuthorization(authorizationCondition),
  withTopBar({ displayWhiteLogo: true }),
)(HomePage);
