import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from '../firebase';
import Button from '@material-ui/core/Button';
import AppBar from './TopBar/AppBar';
import Toolbar from './TopBar/Toolbar';
import TopLogo from './TopBar/TopLogo';
import Hero from './Hero';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100%',
  },
  loginContainer: {
    position: 'absolute',
    zIndex: 1,
    bottom: '15%',
    right: '10%',
  },
  loginButton: {
    fontSize: '2rem',
    backgroundColor: 'rgba(25, 25, 25)',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(40, 40, 40)',
    },
  },
}));

export default function LoginPage(props) {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const history = useHistory();

  // TODO: Add error handling
  function handleLogin() {
    firebase
      .login()
      .then(result => history.push('/'))
      .catch(err => console.error('error: ', err));
  }

  return (
    <>
      <AppBar>
        <Toolbar>
          <TopLogo displayWhiteLogo />
        </Toolbar>
      </AppBar>
      <Hero>
        <div className={classes.loginContainer}>
          <Button
            className={classes.loginButton}
            variant="contained"
            onClick={handleLogin}
          >
            Acceder
          </Button>
        </div>
      </Hero>
    </>
  );
}
