import { toast } from 'react-toastify/dist/index';
import firebase from '../../firebase';
import { toggleIsNetworkBusy } from './ui';

export const LOAD_PLAN_REVISION = 'LOAD_PLAN_REVISION';
export const SET_REVISION_ROWS = 'SET_REVISION_ROWS';
export const SET_COMMENT = 'SET_COMMENT';

function loadPlanRevision(planRevisionData) {
  return { type: LOAD_PLAN_REVISION, payload: planRevisionData };
}

export function fetchAndLoadPlanRevision(history, planId) {
  return async function(dispatch) {
    dispatch(toggleIsNetworkBusy(true));
    try {
      const plan = await firebase.getPlan(planId);
      if (!plan.exists) {
        history.push('/404');
      }
      const planData = plan.data();
      const revisionRows = makeRevisionRows(planData);
      const revisionData = {
        planId,
        ...planData,
        revisionRows,
      };
      dispatch(loadPlanRevision(revisionData));
    } catch (err) {
      console.error('fetchAndLoadPlanRevision error: ', err);
      toast.error(
        'Error al cargar datos de revisión. Por favor inténtalo de nuevo',
      );
    } finally {
      dispatch(toggleIsNetworkBusy(false));
    }
  };
}

function makeRevisionRows(planData) {
  const { targets, actuals } = planData;
  if (actuals) {
    return targets.map((row, i) => ({
      ...row,
      realSales: actuals[i].realSales,
    }));
  }
  return targets.map(row => ({ ...row, realSales: '' }));
}

export function setRevisionRows(revisionRows) {
  return { type: SET_REVISION_ROWS, payload: revisionRows };
}

export function setComment(comment) {
  return { type: SET_COMMENT, payload: comment };
}

export function savePlanRevision(history) {
  return async function(dispatch, getState) {
    dispatch(toggleIsNetworkBusy(true));
    const { planId, revisionRows, comment } = getState().planRevision;

    const revisionData = { planId, actuals: revisionRows, comment };

    try {
      await firebase.savePlanRevision(revisionData, planId);
      toast.success('Guardado con éxito');
      history.push('/history');
    } catch (err) {
      console.error('err: ', err);
      toast.error(err.message, { autoClose: false });
    } finally {
      dispatch(toggleIsNetworkBusy(false));
    }
  };
}
