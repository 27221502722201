import React from 'react';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { PRODUCT_TYPES } from '../../constants';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

/**
 * Formats a boolean cell as a chip
 */
const BooleanFormatter = ({ value }, { isAbridged = false }) => {
  let label;
  let color;
  if (value === PRODUCT_TYPES.technical) {
    label = isAbridged ? 'S' : 'Tratamiento';
    color = blue[50];
  } else if (value === PRODUCT_TYPES.retail) {
    label = isAbridged ? 'R' : 'Reventa';
    color = green[50];
  } else if (value === PRODUCT_TYPES.coloring) {
    label = isAbridged ? 'C' : 'Color + B';
    color = red[50];
  } else {
    return null;
  }
  return (
    <Chip
      label={label}
      size="small"
      style={{ fontSize: '0.63rem', backgroundColor: color }}
    />
  );
};

/**
 * Renders a boolean editing cell as an input
 * TODO: Better named as "multiple choice" or similar
 */
const BooleanEditor = (
  { value, onValueChange, column, row },
  { setEditingCells, rows, setRows, isColoringActive },
) => {
  return (
    <Select
      input={<Input />}
      value={value}
      open
      onChange={event => {
        // Probably more convoluted than it should be
        // Tried passing onCommitChanges but it was buggy
        const changedRows = rows.map(oldRow =>
          oldRow.id === row.id
            ? { ...oldRow, type: event.target.value }
            : oldRow,
        );
        setRows(changedRows);
        // On Tab, move to the sellOut column, else focus out
        // TODO: Parametrize so it moves to the next column
        if (event.keyCode === 13) {
          setEditingCells([{ rowId: row.id, columnName: 'salesWeekCollab' }]);
        } else {
          setEditingCells([]);
        }
      }}
      style={{ width: '100%' }}
    >
      <MenuItem value={PRODUCT_TYPES.technical}>Servicio</MenuItem>
      <MenuItem value={PRODUCT_TYPES.retail}>Reventa</MenuItem>
      {isColoringActive && (
        <MenuItem value={PRODUCT_TYPES.coloring}>Color</MenuItem>
      )}
    </Select>
  );
};

/**
 * Provider for boolean type validation
 */
const BooleanTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={args => BooleanFormatter(args, props.extensions)}
    // We provide setEditingCells so that on change it can move onto next cell
    editorComponent={args => BooleanEditor(args, props.extensions)}
    {...props}
  />
);

export default BooleanTypeProvider;
