import { createClient } from 'contentful';
import { SupportingAction } from './serialization';

/**
 * Returns a list of the active product type keys for a filter
 *
 * @example
 *  // returns ['Servicio', 'Reventa']
 *  getProductTypeKeys({ 'Servicio': true, 'Reventa': true, 'Coloración': false })
 *
 * @param {Object} productTypeFilter - the product type filter for a brand
 * @returns {string[]} An array of product type strings
 */
function getProductTypeKeys(productTypeFilter) {
  return Object.keys(productTypeFilter).filter(
    productTypeKey => productTypeFilter[productTypeKey] === true,
  );
}

/**
 * Contentful service class
 */
class Contentful {
  /**
   * Creates a Contentful service
   * Initializes the client SDK and makes it available as a member
   */
  constructor() {
    this.client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
      environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    });
  }

  /**
   * Fetches the supporting actions from the CMS, given a list of product type
   * filters for brands
   *
   * @param {Object} filters - a map of brands to product type filters
   * @param current - whether to fetch only the current actions, or old ones
   * @returns {Object} - map of supporting actions
   */
  async getSupportingActionsWithFilters(filters, current) {
    const promises = Object.keys(filters).reduce((promiseList, brandKey) => {
      const productTypes = getProductTypeKeys(filters[brandKey]);
      if (productTypes.length > 0) {
        promiseList.push(
          this.client.getEntries({
            content_type: 'animation',
            'fields.isCurrent': current,
            'fields.productType[in]': productTypes.join(','),
            'fields.brand.fields.name': brandKey,
            'fields.brand.sys.contentType.sys.id': 'brand',
          }),
        );
      }
      return promiseList;
    }, []);

    const res = await Promise.all(promises);

    if (res.length === 0) {
      return {};
    }

    return res.reduce((ac, val) => {
      console.log('getSupportingActionsWithFilters val: ', val);
      return {
        ...ac,
        ...val.items
          .map(entry => new SupportingAction(entry))
          .reduce((ac, action) => ({ ...ac, [action['id']]: action }), {}),
      };
    }, {});
  }

  /**
   * Fetches all current the supporting actions available in the CMS
   *
   * @returns {Object} - a map of supporting actions
   */
  async getCurrentSupportingActions() {
    const res = await this.client.getEntries({
      content_type: 'animation',
      'fields.isCurrent': true,
    });
    return res.items
      .map(entry => new SupportingAction(entry))
      .reduce((ac, action) => ({ ...ac, [action['id']]: action }), {});
  }

  /**
   * Gets the supporting actions specified by the list of IDs
   *
   * @param {string[]} idList - a list of supporting action ids
   * @returns {Object}
   */
  async getSupportingActionsByIds(idList) {
    const res = await this.client.getEntries({
      content_type: 'animation',
      'sys.id[in]': idList.join(','),
    });
    return res.items
      .map(entry => new SupportingAction(entry))
      .reduce((ac, action) => ({ ...ac, [action['id']]: action }), {});
  }

  /**
   * Gets the supporting actions of specified brand and product type
   *
   * @param {string} brand - The brand to fetch supporting actions for
   * @param {string} productType - The product type
   * @returns {Promise<{}>}
   */
  async getSupportingActionsByBrandAndProductType(brand, productType) {
    const res = await this.client.getEntries({
      content_type: 'animation',
      'fields.productType': productType,
      'fields.brand.fields.name': brand,
      'fields.brand.sys.contentType.sys.id': 'brand',
      'fields.isCurrent': true,
    });
    // TODO: Refactor - always the same
    return res.items
      .map(entry => new SupportingAction(entry))
      .reduce((ac, action) => ({ ...ac, [action['id']]: action }), {});
  }
}

export const content = new Contentful();
