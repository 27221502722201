import { SET_AUTH_USER } from '../actions/session';

const defaultSession = {
  authUser: null,
};

export default function session(state = defaultSession, action) {
  switch (action.type) {
    case SET_AUTH_USER:
      return { ...state, authUser: action.payload };
    default:
      return state;
  }
}
