import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';
import Button from '@material-ui/core/Button/index';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';

import {
  saveBusinessPlan,
  shareBusinessPlan,
} from '../../state/actions/business-plan';
import SaveAndCommitButtonGroup from './SaveAndCommitButtonGroup';
import CommitModal from './CommitModal';
import Spinner from './Spinner';

const useStyles = makeStyles(theme => ({
  shareButton: {
    backgroundColor: green[100],
    '&:hover': {
      backgroundColor: green[200],
    },
    marginRight: theme.spacing(1),
    verticalAlign: 'top',
  },
  saveButton: {
    backgroundColor: blue[100],
    '&:hover': {
      backgroundColor: blue[200],
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.1rem',
    },
  },
  reviewButton: {
    backgroundColor: blue[100],
    '&:hover': {
      backgroundColor: blue[200],
    },
  },
}));

function SaveControls(props) {
  const classes = useStyles();
  const history = useHistory();
  const { planData, savePlan, sharePlan, mode, isNetworkBusy } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const handleSave = shouldCommit => {
    savePlan(history, planData, shouldCommit, true);
  };

  const handleShare = () => {
    sharePlan(planData);
  };

  return (
    <div className={classes.root}>
      <CommitModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleSave={handleSave}
        isSaving={isNetworkBusy}
        spinnerComponent={<Spinner />}
      />
      <Button
        className={classes.shareButton}
        onClick={handleShare}
        variant="outlined"
        size="small"
        startIcon={isNetworkBusy ? <Spinner /> : <WhatsAppIcon />}
        disabled={isNetworkBusy}
        disableElevation
      >
        Compartir
      </Button>
      {mode === 'view' && (
        <Button
          className={classes.reviewButton}
          component={Link}
          to={`/plan/review/${planData.planId}`}
          variant="outlined"
          size="small"
          startIcon={<AssignmentTurnedInOutlinedIcon />}
          disableElevation
        >
          Revisar
        </Button>
      )}
      {mode !== 'view' && (
        <SaveAndCommitButtonGroup
          isSaving={isNetworkBusy}
          handleSave={handleSave}
          setModalOpen={setModalOpen}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isNetworkBusy: state.ui.isNetworkBusy,
    planData: {
      ...state.businessPlan,
      repEmail: state.session.authUser.email,
      repId: state.session.authUser.uid,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    savePlan: (browserHistory, businessPlanData, shouldCommit, shouldForward) =>
      dispatch(
        saveBusinessPlan(
          browserHistory,
          businessPlanData,
          shouldCommit,
          shouldForward,
        ),
      ),
    sharePlan: businessPlanData =>
      dispatch(shareBusinessPlan(businessPlanData)),
  };
}

const ConnectedSaveControls = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveControls);
export default ConnectedSaveControls;
