import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  getNumCollabs,
  getPrevColoringSales,
  getPrevRetailSales,
  getPrevSalesPeriod,
  getPrevServiceSales,
} from './common';

function createPrevSalesPWCSelector(prevSalesGetter) {
  return createSelector(
    [prevSalesGetter, getPrevSalesPeriod, getNumCollabs],
    (prevSalesInUnits, prevSalesPeriod, numCollabs) => {
      return _.round(
        Number(prevSalesInUnits) / prevSalesPeriod / numCollabs,
        1,
      );
    },
  );
}

export const getPrevServiceSalesPWC = createPrevSalesPWCSelector(
  getPrevServiceSales,
);
export const getPrevRetailSalesPWC = createPrevSalesPWCSelector(
  getPrevRetailSales,
);
export const getPrevColoringSalesPWC = createPrevSalesPWCSelector(
  getPrevColoringSales,
);
