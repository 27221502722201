import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Chip from '@material-ui/core/Chip';
import { blue, green, grey } from '@material-ui/core/colors';
import { PLAN_STATES } from '../../constants';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8rem',
  },
  inProgress: {
    backgroundColor: blue[50],
  },
  finalized: {
    backgroundColor: green[50],
  },
  pending: {
    backgroundColor: grey[200],
  },
}));

function ChipFormatter({ value }) {
  const classes = useStyles();

  return (
    <Chip
      label={value}
      className={clsx(classes.root, {
        [classes.inProgress]: value === PLAN_STATES.inProgress,
        [classes.finalized]: value === PLAN_STATES.finalized,
        [classes.pending]: value === PLAN_STATES.pending,
      })}
    />
  );
}

export default function ChipTypeProvider(props) {
  return <DataTypeProvider formatterComponent={ChipFormatter} {...props} />;
}
