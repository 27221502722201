import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

export default function NotFoundPage(props) {
  const classes = useStyles();
  return (
    <div>
      <p>404: No se encuentra la página</p>
    </div>
  );
}
