import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    paddingTop: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
}));

export default function SubViewMainContentArea({
  classes: propClasses,
  children,
}) {
  const classes = useStyles();

  return (
    <Container className={clsx(classes.root, propClasses)}>
      {children}
    </Container>
  );
}
