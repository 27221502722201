import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import SummaryView from './SummaryView';
import Modal from '@material-ui/core/Modal';
import ModalContainer from './ModalContainer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StepButton from '@material-ui/core/StepButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftSharp';
import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';
import SaveControls from './PlanSaveControls/PlanSaveControls';

import withTopBar from './withTopBar';
import withAuthorization from './withAuthorization';
import YourBusinessView from './InitialBusinessDiagnosticView';
import BusinessActionsView from './SupportingActions/SupportingActionView';
import BusinessModelView from './BusinessModelView.jsx';
import { setActiveStep, toggleModal } from '../state/actions/ui';
import { setCustomerId, setCustomerName } from '../state/actions/business-plan';
import RoundButton from './common/RoundButton';

const useStyles = makeStyles(theme => ({
  topControlsContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 4),
  },
  stepper: {
    padding: theme.spacing(0),
  },
  stepperContainer: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.default,
    },
    marginRight: theme.spacing(2),
  },
  customerNameContainer: {
    '& .MuiTypography-root': {
      fontSize: '0.75rem',
    },
    marginRight: theme.spacing(4),
  },
  stepTitle: {
    fontSize: '1.65em',
  },
  topControlsInnerContainer: {
    margin: '0 auto',
    maxWidth: theme.breakpoints.values.lg,
  },
  customerInfo: {
    marginRight: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  stepButtonContainer: {
    '& button:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  stepButton: {
    padding: '1px',
    borderRadius: '50%',
    minWidth: 0,
    color: theme.palette.text.secondary,
  },
  finalActionContainer: {
    marginRight: theme.spacing(3),
  },
}));

const VIEWS = {
  analysis: 'Tu negocio',
  animations: 'Acciones',
  bizplan: 'Plan de negocio',
  summary: 'Resumen',
};

function getSteps() {
  return Object.values(VIEWS);
}

function getStepTitle(stepNumber) {
  switch (stepNumber) {
    case 0:
      return '¿Cómo va tu negocio?';
    case 1:
      return 'Hagamos un plan de negocio';
    case 2:
      return 'Tu catálogo de soluciones';
    case 3:
    default:
      return 'Resumen de plan';
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// TODO: Change PlanPage to a class component so we can do cleanup on unmount
function PlanPage({
  activeStep,
  onActiveStepChange,
  isModalOpen,
  handleToggleModal,
  mode,
  planId,
  customerId,
  customerName,
  setCustomerName,
  setCustomerId,
}) {
  const classes = useStyles();
  const query = useQuery();

  // TODO:
  // Substitute this with the following logic
  // if (planId)
  //  fetch plan data and load to state
  // else
  //  grab customer name and id from params
  //  if not available, redirect to error page
  const receivedCustomerName =
    query.get('name') || customerName || 'Nombre de cliente';

  const receivedCustomerId = query.get('customerId') || customerId;

  setCustomerId(receivedCustomerId);
  setCustomerName(receivedCustomerName);

  if (mode === 'view') {
    onActiveStepChange(3);
  }

  const steps = getSteps();

  const handleStep = stepIndex => {
    onActiveStepChange(stepIndex);
  };

  return (
    <div>
      <Grid
        className={classes.topControlsContainer}
        container
        justify="flex-start"
        alignItems="center"
      >
        <Grid className={classes.customerNameContainer} item>
          <Typography variant="body2">{receivedCustomerName}</Typography>
          <Typography variant="body2" color="textSecondary">
            Plan de negocio
          </Typography>
        </Grid>
        {mode !== 'view' && (
          <Grid className={classes.stepperContainer} item xs={3}>
            {/* TODO: Abstract stepper into its own component */}
            {/* Make it dumb, and pass the function down from this component */}
            <Stepper
              className={classes.stepper}
              activeStep={activeStep}
              nonLinear
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={() => handleStep(index)}>
                    <StepLabel />
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Grid>
        )}
        <Grid item style={{ flexGrow: 1 }}>
          <Typography className={classes.stepTitle} variant="h4">
            {getStepTitle(activeStep)}
          </Typography>
        </Grid>
        {activeStep === 3 && (
          <Grid className={classes.finalActionContainer} item>
            <SaveControls planId={planId} mode={mode} />
          </Grid>
        )}
        {mode !== 'view' && (
          <Grid className={classes.stepButtonContainer} item>
            <RoundButton
              disabled={activeStep === 0}
              onClick={() => handleStep(activeStep - 1)}
            >
              <ChevronLeftIcon />
            </RoundButton>
            <RoundButton
              onClick={() => handleStep(activeStep + 1)}
              disabled={activeStep === 3}
            >
              <ChevronRightIcon />
            </RoundButton>
          </Grid>
        )}
      </Grid>
      {activeStep === 0 && <YourBusinessView />}
      {activeStep === 1 && <BusinessModelView />}
      {activeStep === 2 && <BusinessActionsView />}
      {activeStep === 3 && <SummaryView isEditable={true} />}
      <Modal open={isModalOpen} onClose={handleToggleModal}>
        <ModalContainer onClickClose={handleToggleModal} />
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  const { activeStep, isModalOpen } = state.ui;
  return {
    activeStep,
    isModalOpen,
    planId: state.businessPlan.planId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onActiveStepChange: val => dispatch(setActiveStep(val)),
    handleToggleModal: () => dispatch(toggleModal()),
    setCustomerId: id => dispatch(setCustomerId(id)),
    setCustomerName: name => dispatch(setCustomerName(name)),
  };
}

const authorizationCondition = authUser => !!authUser;

export default compose(
  withAuthorization(authorizationCondition),
  withTopBar(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PlanPage);
