import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  legendRow: {
    marginBottom: theme.spacing(1),
  },
  legendSymbol: {
    height: 16,
    width: 16,
  },
  textGridItem: {
    paddingLeft: theme.spacing(1.5),
  },
  legendText: {
    fontSize: '0.72rem',
  },
}));

export default function ChartLegend(props) {
  const classes = useStyles();

  const { legendItems, className } = props;

  return (
    <Container className={className || classes.root}>
      {legendItems.map(legendItem => (
        <Grid
          className={classes.legendRow}
          key={legendItem.text}
          wrap="nowrap"
          container
        >
          <Grid item>
            <div
              className={classes.legendSymbol}
              style={{ backgroundColor: legendItem.fill }}
            />
          </Grid>
          <Grid className={classes.textGridItem} item>
            <Typography
              className={classes.legendText}
              variant="body2"
              color="textSecondary"
            >
              {legendItem.text}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Container>
  );
}
