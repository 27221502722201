import React from 'react';
import { AppBar as MuiAppBar } from '@material-ui/core';

export default function AppBar(props) {
  return (
    <MuiAppBar position="static" color="transparent" elevation={0}>
      {props.children}
    </MuiAppBar>
  );
}
