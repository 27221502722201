/**
 * Commits an entire row and updates state on changes to a cell value
 *
 * @param value - The value of the cell being changed
 * @param row - The row being changed
 * @param rows - The entire data for rows
 * @param setRows - Setting the row data
 * @param columnKey - The column key to change
 */
import { PRODUCT_TYPES } from '../../constants';

export const commitRowChanges = ({ value, row, rows, setRows, columnKey }) => {
  const changedRows = rows.map(oldRow =>
    oldRow.id === row.id ? { ...oldRow, [columnKey]: value } : oldRow,
  );
  setRows(changedRows);
};

/**
 * On committing a cell with a number, we check if it's a string representing
 * a number, and return the parsed value; otherwise, we default to 0.
 *
 * @param value
 * @returns {number}
 */
export const validateNumber = value => {
  const re = /^[0-9]+$/;
  if (value === '' || !re.test(value)) {
    return 0;
  }
  return Number.parseInt(value);
};

export const validateFloat = value => {
  const re = /^[0-9]+,?[0-9]*?$/;
  if (value === '' || !re.test(value)) {
    return 0;
  }
  return Number.parseFloat(value.replace(',', '.'));
};

/**
 * SUMMARY APPEND
 */
export const SUMMARY_ROW_INDEX = {
  technical: 0,
  retail: 1,
  total: 2,
};

const summaryReducer = (ac, row, i, arr, salesPeriod, numCollabs) => {
  const summary = ac.map(item => {
    return { ...item };
  });

  const {
    salesWeekCollab,
    averageSalesPrice,
    averageUnitCost,
    realSales,
  } = row;

  let rowIndex;
  if (row.type === PRODUCT_TYPES.technical) {
    rowIndex = 0;
  } else if (row.type === PRODUCT_TYPES.retail) {
    rowIndex = 1;
  } else if (row.type === PRODUCT_TYPES.coloring) {
    rowIndex = 2;
  }

  summary[rowIndex].salesWeekCollab += salesWeekCollab;
  summary[rowIndex].realSales += realSales;

  // const swc = summary[rowIndex].salesWeekCollab;
  const sellOut = salesWeekCollab * salesPeriod * numCollabs;
  const income = sellOut * averageSalesPrice;
  const totalCost = sellOut * averageUnitCost;
  const profit = income - totalCost;

  summary[rowIndex].sellOut += sellOut;
  summary[rowIndex].income += income;
  summary[rowIndex].totalCost += totalCost;
  summary[rowIndex].profit += profit;

  const summaryRowIndex = summary.length - 1;
  summary[summaryRowIndex].salesWeekCollab += salesWeekCollab;
  summary[summaryRowIndex].sellOut += sellOut;
  summary[summaryRowIndex].income += income;
  summary[summaryRowIndex].totalCost += totalCost;
  summary[summaryRowIndex].profit += profit;
  summary[summaryRowIndex].realSales += realSales;

  return summary;
};

function generateAccumulatorRow(type) {
  return {
    type,
    product: 'TOTAL',
    salesWeekCollab: 0,
    profit: 0,
    income: 0,
    totalCost: 0,
    sellOut: 0,
    realSales: 0,
  };
}

export const generateSummaryRows = (
  rows,
  salesPeriod,
  numCollabs,
  isColoringActive,
) => {
  const summaryRowsAccumulator = [
    generateAccumulatorRow(PRODUCT_TYPES.technical),
    generateAccumulatorRow(PRODUCT_TYPES.retail),
    // The total line expects an undefined product type
    generateAccumulatorRow(undefined),
  ];

  if (isColoringActive) {
    summaryRowsAccumulator.splice(
      2,
      0,
      generateAccumulatorRow(PRODUCT_TYPES.coloring),
    );
  }

  return rows.reduce(
    (ac, row, i, arr) =>
      summaryReducer(ac, row, i, arr, salesPeriod, numCollabs),
    summaryRowsAccumulator,
  );
};
