import { ACTION_TYPE_NAMES } from '../../constants';

export class SupportingAction {
  constructor(contentfulEntry) {
    console.log('contentfulEntry: ', contentfulEntry);
    const { sys, fields } = contentfulEntry;
    this.id = sys.id;
    this.contentType = sys.contentType.sys.id;
    this.title = fields.title;
    this.brand = fields.brand.fields.name;
    this.imageUrl = fields.image.fields.file.url;
    this.shortDescription = fields.shortDescription;
    this.productType = fields.productType;
    this.actionType = actionTypesMap[fields.actionType];
    this.isCurrent = fields.isCurrent;
    this.whoIsItFor = fields.whoIsItFor;
    this.howDoesItWork = fields.howDoesItWork;
    this.howDoesItHelp = fields.howDoesItHelp;
    this.whyNow = fields.whyNow;
    this.isSelected = false;
    this.quantity = 1;
    const { goodies, additionalLinks } = fields;
    this.goodies =
      goodies &&
      goodies.reduce((ac, goodie) => {
        ac[goodie.sys.id] = new Goodie(goodie);
        return ac;
      }, {});
    this.additionalLinks =
      additionalLinks && additionalLinks.map(data => new Downloadable(data));
  }
}

class Goodie {
  constructor(contentfulResponse) {
    console.log('contentfulResponse', contentfulResponse);
    const { sys, fields } = contentfulResponse;
    this.id = sys.id;
    this.title = fields.title;
    this.altTitle = fields.altTitle;
    this.fullPicture = fields?.fullPicture?.fields?.file.url;
    this.previewPicture = fields?.previewPicture?.fields?.file.url;
    this.isQuantityBased = fields.isQuantityBased;
    this.quantity = fields.isQuantityBased ? 1 : null;
    // TODO: Add option for only one downloadable
    this.downloadables = [];
    if (fields.downloadables && fields.downloadables.length >= 1) {
      this.downloadables = fields.downloadables.map(
        downloadable => new Downloadable(downloadable),
      );
    }
  }
}

class Downloadable {
  constructor(downloadable) {
    this.id = downloadable.sys.id;
    this.title = downloadable?.fields.title;
    this.file = downloadable?.fields?.file.url;
  }
}

const actionTypesMap = {
  Animación: ACTION_TYPE_NAMES.animation,
  Coaching: ACTION_TYPE_NAMES.coaching,
};
