import { toast } from 'react-toastify';
import firebase from '../../firebase';
import { showBackdropWithSpinner } from './ui';

export function getCustomerListByBrandScopes(organization) {
  return async function(dispatch, getState) {
    dispatch(showBackdropWithSpinner(true));
    try {
      console.log('getCustomerListByBrandScopes called: ', organization);
      const snapshot = await firebase.getCustomersByBrandScopes(organization);
      return snapshot.docs.map(doc => ({
        docId: doc.id,
        ...doc.data(),
      }));
    } catch (err) {
      console.error('getCustomerListByBrandScopes error: ', err);
      toast.error(
        'Error al cargar lista de clientes. Por favor inténtalo de nuevo.',
      );
    } finally {
      dispatch(showBackdropWithSpinner(false));
    }
  };
}

export function uploadCustomerCsv(fileData) {
  return async function(dispatch) {
    try {
      await firebase.uploadCustomerCsv(fileData);
      toast.success('Se ha cargado con éxito');
    } catch (err) {
      console.error('uploadCustomerCsv error: ', err);
      toast.error('Error en la subida: ', err.message);
    }
  };
}

export function addCustomer(customerData) {
  return async function(dispatch) {
    try {
      await firebase.addCustomer(customerData);
      toast.success('Se ha añadido correctamente');
    } catch (err) {
      console.error('addCustomer error', err);
      toast.error('Error al añadir cliente: ', err);
      throw err;
    }
  };
}

export function editCustomer(customerData) {
  return async function(dispatch) {
    try {
      await firebase.editCustomer(customerData);
      toast.success('Se ha modificado correctamente');
    } catch (err) {
      console.error('editCustomer error', err);
      toast.error('Error al modificar datos de cliente: ', err);
      throw err;
    }
  };
}

export function removeCustomer(customerData) {
  return async function(dispatch) {
    try {
      await firebase.removeCustomer(customerData);
      toast.success('Se ha eliminado correctamente');
    } catch (err) {
      console.error('removeCustomer error: ', err);
      toast.error('Error al eliminar cliente: ', err);
      throw err;
    }
  };
}
