import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  stepButton: {
    padding: '1px',
    borderRadius: '50%',
    minWidth: 0,
    color: theme.palette.text.secondary,
  },
}));

export default function RoundButton({
  size = 'small',
  onClick,
  disabled,
  children,
}) {
  const classes = useStyles();

  return (
    <Button
      className={classes.stepButton}
      size={size}
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
