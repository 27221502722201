import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { handleNextSalesPeriodChange } from "../../state/actions/business-plan";

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiInputBase-input': {
      paddingTop: 7,
      paddingBottom: 7,
    },
  },
}));

function NextPeriodInput(props) {
  const classes = useStyles();
  const { nextSalesPeriod, handleNextSalesPeriodChange } = props;
  return (
    <TextField
      className={classes.textField}
      size="small"
      label="Semanas de Venta"
      value={nextSalesPeriod}
      onBlur={e => {
        if (e.target.value === '') {
          handleNextSalesPeriodChange(1);
        }
      }}
      onFocus={e => e.target.select()}
      onChange={e => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
          handleNextSalesPeriodChange(e.target.value);
        }
      }}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
    />
  );
}

function mapStateToProps(state) {
  return {
    nextSalesPeriod: state.businessPlan.nextSalesPeriod,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleNextSalesPeriodChange: newPeriod =>
      dispatch(handleNextSalesPeriodChange(newPeriod)),
  };
}

const ConnectedNextPeriodInput = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NextPeriodInput);

export default ConnectedNextPeriodInput;
