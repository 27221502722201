import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ModalContainer from './ModalContainer';
import withTopBar from './withTopBar';
import SummaryView from './SummaryView';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../media/logo_full_black_2.png';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { toggleModal } from '../state/actions/ui';

const useStyles = makeStyles(theme => ({
  appBar: {
    marginBottom: theme.spacing(2),
  },
  logo: {
    height: 20,
  },
  mainContentContainer: {
    maxWidth: 1200,
  },
  planContextContainer: {
    padding: theme.spacing(0, 3),
  },
  planDatesContainer: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: '0.78rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: 'unset',
    },
  },
  nameContainer: {
    flexGrow: 1,
  },
  creationContextContainer: {
    fontStyle: 'italic',
    '& > *': {
      fontSize: '0.59rem',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
      '& > *': {
        fontSize: '0.69rem',
      },
    },
  },
  divider: {
    margin: theme.spacing(2, 3, 3),
  },
}));

export default function PlanViewSelector(props) {
  const { mode } = props;
  if (mode === 'view') {
    return <ConnectedPlanViewPageWithTopBar {...props} />;
  } else {
    return <ConnectedPlanViewPage {...props} />;
  }
}

function getPlanDateString(planDetails) {
  const basicText = 'Plan de desarrollo';
  const { activationDate, finishDate } = planDetails;
  if (activationDate && finishDate) {
    return `${basicText} - de ${activationDate} a ${finishDate}`;
  } else {
    return `${basicText} (por confirmar)`;
  }
}

function PlanViewPage(props) {
  const classes = useStyles();
  const { isModalOpen, handleToggleModal, planDetails } = props;
  return (
    <div>
      <AppBar
        className={classes.appBar}
        position="static"
        color="transparent"
        elevation={0}
      >
        <Toolbar variant="dense">
          <img
            className={classes.logo}
            src={logo}
            alt="Kérastase Boost Your Business Logo"
          />
        </Toolbar>
      </AppBar>
      <Container className={classes.mainContentContainer}>
        <Grid
          className={classes.planContextContainer}
          alignItems="center"
          container
        >
          <Grid className={classes.nameContainer} item>
            <Typography variant="h4">{planDetails.customerName}</Typography>
            <Typography
              className={classes.planDatesContainer}
              variant="body1"
              color="textSecondary"
            >
              {getPlanDateString(planDetails)}
            </Typography>
          </Grid>
          <Grid className={classes.creationContextContainer} item>
            <Typography variant="body2" color="textSecondary">
              Creado el: {planDetails.createdAt}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Últ. modificación el: {planDetails.createdAt}
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <SummaryView />
      </Container>
      <Modal open={isModalOpen} onClose={handleToggleModal}>
        <ModalContainer onClickClose={handleToggleModal} />
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  const { isModalOpen } = state.ui;
  return {
    isModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleToggleModal: () => dispatch(toggleModal()),
  };
}

const ConnectedPlanViewPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanViewPage);

const ConnectedPlanViewPageWithTopBar = withTopBar()(ConnectedPlanViewPage);
