import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';

import { FirebaseContext } from '../firebase';

import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import { toast } from 'react-toastify';

import { toPlanHistoryRows } from '../utils/dataUtils';

import withTopBar from './withTopBar';
import withAuthorization from './withAuthorization';
import SubViewContainer from './SubViewContainer';
import PlanHistoryTable from './PlanHistoryTable/PlanHistoryTable';

function PlanHistoryPage(props) {
  const firebase = useContext(FirebaseContext);

  const [planData, setPlanData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const authUser = useSelector(state => state.session.authUser);

  // TODO: Move plan fetching logic to Redux
  useEffect(() => {
    async function getAllPlans(repId) {
      try {
        const planSnapshots = await firebase.getAllPlans(repId);
        const plans = toPlanHistoryRows(
          planSnapshots.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          })),
        );
        setPlanData(plans);
      } catch (error) {
        console.error('Error fetching plans: ', error);
        toast.error('Error al descargar datos. Por favor inténtalo de nuevo.');
      }
      setIsLoading(false);
    }

    if (authUser.uid) {
      getAllPlans(authUser.uid);
    }
  }, [authUser.uid]);

  return (
    <SubViewContainer title="Histórico de planes">
      {isLoading ? (
        'Cargando...'
      ) : (
        <Fade in timeout={1500}>
          <Paper elevation={0} variant="outlined" square>
            <PlanHistoryTable rows={planData} setRows={setPlanData} />
          </Paper>
        </Fade>
      )}
    </SubViewContainer>
  );
}

const authorizationCondition = authUser => !!authUser;

export default compose(
  withAuthorization(authorizationCondition),
  withTopBar(),
)(PlanHistoryPage);
