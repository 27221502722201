import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateTypeProvider from '../PlanHistoryTable/DateTypeProvider';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({}));

const BrandsFormatter = ({ value }) => {
  if (Array.isArray(value)) {
    return value.sort().join('; ');
  }
  return String(value);
};

const brands = ['kerastase', 'lp', 'redken', 'matrix', 'shu'];

const BrandsEditor = ({ value: selectedBrands = [], onValueChange }) => {
  return (
    <Select
      labelId="customer-table-brands-editor-formatter-label"
      id="customer-table-brands-editor-formatter"
      multiple
      value={selectedBrands}
      onChange={ev => onValueChange(ev.target.value)}
      renderValue={selected => selected.sort().join('; ')}
    >
      {brands.map(brand => (
        <MenuItem key={brand} value={brand}>
          <Checkbox checked={selectedBrands.indexOf(brand) > -1} />
          <ListItemText primary={brand} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default function BrandsTypeProvider({ isSuperAdmin, ...props }) {
  return (
    <DateTypeProvider
      formatterComponent={BrandsFormatter}
      editorComponent={BrandsEditor}
      {...props}
    />
  );
}
