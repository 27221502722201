import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import { Chip } from '@material-ui/core/index';

const useStyles = makeStyles(theme => ({
  chip: {
    borderRadius: 0,
    fontSize: '0.7rem',
    height: 'unset',
    '& .MuiChip-label': {
      padding: theme.spacing(0, 0.5),
    },
  },
}));

const SupportingActionInfoChip = ({ label, backgroundColor }) => {
  const classes = useStyles();

  return (
    <Chip
      className={classes.chip}
      label={label}
      style={backgroundColor && { backgroundColor }}
    />
  );
};

export default SupportingActionInfoChip;
