import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2, 2, 2),
  },
  cardHeader: {
    fontSize: '1.1rem',
  },
  divider: {
    margin: theme.spacing(2, 0, 0),
  },
  table: {
    '& .MuiTableCell-root': {
      fontSize: '0.73rem',
      padding: theme.spacing(1, 0.5),
    },
  },
  tableHead: {
    backgroundColor: 'rgb(250, 250, 250)',
  },
}));

function getRowLabel(rowKey) {
  return rowKey === 'total' ? 'Todo' : '30 días';
}

export default function MetricsCard(props) {
  const classes = useStyles();
  const { metrics } = props;

  return (
    <div>
      <Card className={classes.card} square>
        <Typography className={classes.cardHeader}>
          {metrics.subject}
        </Typography>
        <Divider className={classes.divider} />
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Período</TableCell>
                <TableCell align="right">Creados</TableCell>
                <TableCell align="right">Compartidos</TableCell>
                <TableCell align="right">Finalizados</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metrics.rows.length > 0 &&
                metrics.rows.map((row) => (
                  <TableRow key={row.key}>
                    <TableCell component="th" scope="row">
                      {getRowLabel(row.key)}
                    </TableCell>
                    <TableCell align="right">{row.created}</TableCell>
                    <TableCell align="right">{row.shared}</TableCell>
                    <TableCell align="right">{row.revised}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
}
