import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Input from '@material-ui/core/Input';
import { commitRowChanges, validateNumber, validateFloat } from './utils';

/**
 * Custom editor for Number type cells
 */
const NumberEditor = (
  { value, onValueChange, row, column },
  { rows, setRows, setEditingCells },
  type,
) => {
  const validator = type === 'currency' ? validateFloat : validateNumber;

  return (
    <Input
      defaultValue={String(value).replace('.', ',')}
      inputProps={{ style: { textAlign: 'right', fontSize: '0.75rem' } }}
      autoFocus
      onFocus={(e) => e.target.select()}
      onKeyDown={(e) => {
        // On Enter or Tab, commit changes and exit editing
        if (e.keyCode === 13 || e.keyCode === 9) {
          commitRowChanges({
            value: validator(e.target.value),
            row,
            rows,
            setRows,
            columnKey: column.name,
          });
          // TODO: Switch focus to next line or add a new one
          setEditingCells([]);
        } else if (
          (e.keyCode !== 8 && e.keyCode < 48) ||
          (e.keyCode > 57 && e.keyCode !== 188)
        ) {
          e.preventDefault();
        }
      }}
      onBlur={(e) => {
        // If focus is changed without click menas, commit and exit editing
        commitRowChanges({
          value: validator(e.target.value),
          row,
          rows,
          setRows,
          columnKey: column.name,
        });
        setEditingCells([]);
      }}
      fullWidth
    />
  );
};

/**
 * @return {string}
 */
function CurrencyFormatter({ value }) {
  return `${new Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)}€`;
}

export function EditableCurrencyTypeProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={CurrencyFormatter}
      editorComponent={(args) =>
        NumberEditor(args, props.extensions, 'currency')
      }
      {...props}
    />
  );
}

export function CurrencyTypeProvider(props) {
  return <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />;
}

export function EditableNumberTypeProvider(props) {
  return (
    <DataTypeProvider
      editorComponent={(args) => NumberEditor(args, props.extensions)}
      {...props}
    />
  );
}

/**
 * Provider for number cell validation
 */
const NumberTypeProvider = (props) => (
  <DataTypeProvider
    editorComponent={(args) => NumberEditor(args, props.extensions)}
    {...props}
  />
);

export default NumberTypeProvider;
