import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { cyan } from '@material-ui/core/colors';

const headFont = 'Caslon, serif';

export default createMuiTheme({
  typography: {
    fontFamily: 'proxima-nova, sans-serif',
    h4: {
      fontFamily: headFont,
      fontWeight: 400,
    },
    h5: {
      fontFamily: headFont,
    },
    body1: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: cyan[900],
    },
  },
  shape: {
    borderRadius: 2,
  },
});
