import React from 'react';
import TopBar from './TopBar/TopBar';

export default function withTopBar(topBarProps) {
  return function(Component) {
    return function WithTopBar(props) {
      const barProps = topBarProps || {};
      return (
        <div>
          <TopBar {...barProps} />
          <Component {...props} />
        </div>
      );
    };
  };
}
