import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useParams, Link as RouterLink, useHistory } from 'react-router-dom';

import { FirebaseContext } from '../firebase';

import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PlanHistoryTable from './PlanHistoryTable/PlanHistoryTable';
import Paper from '@material-ui/core/Paper';

import withTopBar from './withTopBar';
import withAuthorization from './withAuthorization';
import headerImage from '../media/customer_page_header.jpg';

import { toPlanHistoryRows } from '../utils/dataUtils';
import { resetBusinessPlan } from '../state/actions/business-plan';
import { setActiveStep } from '../state/actions/ui';

const useStyles = makeStyles(theme => ({
  customerHeaderContainer: {
    marginTop: theme.spacing(3),
    backgroundImage: `url(${headerImage})`,
    backgroundPosition: 'left bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: 320,
    padding: theme.spacing(3),
  },
  customerName: {
    fontSize: '2rem',
  },
  createPlanButton: {
    float: 'right',
    marginTop: theme.spacing(2),
    backgroundColor: 'rgba(15, 15, 15)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(35, 35, 35)',
    },
  },
}));

function CustomerPage(props) {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const history = useHistory();

  const { customerList, repEmail } = props;
  const { customerId } = useParams();

  const [customerPlans, setCustomerPlans] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const customerData = customerList
    ? customerList.find(customer => customer.id === customerId)
    : [];

  // TODO: Move plan-fetching to Redux
  useEffect(() => {
    async function getAllPlans() {
      try {
        const planSnapshots = await firebase.getPlansByCustomerId(
          customerId,
          repEmail,
        );
        const plans = toPlanHistoryRows(
          planSnapshots.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          })),
        );
        setCustomerPlans(plans);
      } catch (err) {
        console.error('CustomerPage: Error fetching plans: ', err);
        toast.error(
          'Error al descargar los planes de cliente. Inténtalo más tarde',
        );
      }
      setIsLoading(false);
    }
    getAllPlans();
  }, [customerData]);

  return (
    <Container>
      <Grid
        className={classes.customerHeaderContainer}
        container
        justify="flex-end"
        alignItems="flex-start"
      >
        {customerData && (
          <Grid item>
            <Typography className={classes.customerName} variant="h3">
              {customerData.companyName}
            </Typography>
            <Button
              className={classes.createPlanButton}
              variant="outlined"
              // component={RouterLink}
              // to={`/plan?customerId=${customerId}&name=${customerData.companyName}`}
              onClick={() => {
                props.resetBusinessPlan();
                props.setActiveStep(0);
                history.push(
                  `/plan?customerId=${customerId}&name=${customerData.companyName}`,
                );
              }}
            >
              Crear Plan de Negocio
            </Button>
          </Grid>
        )}
      </Grid>
      {isLoading ? (
        'Cargando...'
      ) : (
        <Paper elevation={0} variant="outlined" square>
          <PlanHistoryTable
            showNameColumn={false}
            rows={customerPlans}
            setRows={setCustomerPlans}
          />
        </Paper>
      )}
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    repEmail: state.session.authUser.email,
    customerList: state.ui.customerList,
  };
}

const mapDispatchToProps = {
  resetBusinessPlan,
  setActiveStep,
};

const authorizationCondition = authUser => !!authUser;

export default compose(
  withAuthorization(authorizationCondition),
  withTopBar(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CustomerPage);
