import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'date-fns';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles(theme => ({
  sendButton: {
    backgroundColor: blue[100],
    '&:hover': {
      backgroundColor: blue[200],
    },
  },
  pageTitleContainer: {
    marginRight: theme.spacing(4),
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
}));

const spinner = <CircularProgress color="inherit" size={12} />;

export default function PlanRevisionHeader({
  handleSave,
  isNetworkBusy,
  customerName,
  activationDate,
  finishDate,
}) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid className={classes.pageTitleContainer} item>
        <Typography variant="h4">Revisión de plan</Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={handleSave}
          disabled={isNetworkBusy}
          className={classes.sendButton}
          variant="outlined"
          startIcon={isNetworkBusy ? spinner : <SaveIcon />}
          size="small"
          disableElevation
        >
          Guardar
        </Button>
      </Grid>
      <Grid item xs={12}>
        {customerName && (
          <Typography variant="body2" color="textSecondary">
            {customerName} ({format(activationDate.toDate(), 'dd/MM/yy')} -{' '}
            {format(finishDate.toDate(), 'dd/MM/yy')})
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
