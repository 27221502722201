import _ from 'lodash';
import { formatISO, format } from 'date-fns';

export const toPlanObjectWithReadableDates = (planDoc) => {
  const plan = planDoc;
  const DATE_FMT = 'dd/MM/yy';
  return {
    ...plan,
    modifiedAt: plan.modifiedAt
      ? format(plan.modifiedAt.toDate(), DATE_FMT)
      : undefined,
    createdAt: plan.createdAt
      ? format(plan.createdAt.toDate(), DATE_FMT)
      : undefined,
    activationDate: plan.activationDate
      ? format(plan.activationDate.toDate(), DATE_FMT)
      : undefined,
    finishDate: plan.activationDate
      ? format(plan.finishDate.toDate(), DATE_FMT)
      : undefined,
  };
};

export const toPlanHistoryRows = (listOfPlans) => {
  return listOfPlans.map((plan) => {
    return {
      modifiedAt: plan.modifiedAt
        ? formatISO(plan.modifiedAt.toDate())
        : undefined,
      id: plan.id,
      salon: plan.customerName,
      state: plan.state,
      activationDate: plan.activationDate
        ? formatISO(plan.activationDate.toDate())
        : undefined,
      finishDate: plan.finishDate
        ? formatISO(plan.finishDate.toDate())
        : undefined,
    };
  });
};

export const serializeAnimationData = (rawAnimationData) => {
  const ret = rawAnimationData
    .filter((animation) => animation.isSelected)
    .map((animation) => {
      return {
        id: animation.id,
        linkedAnimationGoodies: animation.linkedAnimationGoodies.map(
          (goodie) => {
            return {
              id: goodie.id,
              quantity: goodie.quantity,
            };
          },
        ),
      };
    });
  return ret;
};

export const serializeBusinessActionData = (rawBusinessActionData) => {
  return rawBusinessActionData
    .filter((action) => action.isSelected)
    .map((action) => action.id);
};

export const calculatePotentials = (
  prevSalesWeekCollabRetail,
  prevSalesWeekCollabService,
  curSalesWeekCollabRetail,
  curSalesWeekCollabService,
  retailPotential,
  servicePotential,
) => {
  const totalPotential = retailPotential + servicePotential;
  const prevTotal = prevSalesWeekCollabService + prevSalesWeekCollabRetail;
  const curTotal = curSalesWeekCollabRetail + curSalesWeekCollabService;

  return {
    retail: {
      prevSalesWeekCollabRetail,
      curSalesWeekCollabRetail,
      potential: retailPotential,
      prev: (prevSalesWeekCollabRetail / retailPotential) * 100,
      next: (curSalesWeekCollabRetail / retailPotential) * 100,
    },
    service: {
      prevSalesWeekCollabService,
      curSalesWeekCollabService,
      potential: servicePotential,
      prev: (prevSalesWeekCollabService / servicePotential) * 100,
      next: (curSalesWeekCollabService / servicePotential) * 100,
    },
    total: {
      prevTotal,
      curTotal,
      potential: totalPotential,
      prev: _.round((prevTotal / totalPotential) * 100, 1),
      next: _.round((curTotal / totalPotential) * 100, 1),
    },
  };
};

/**
 * Checks if the plan has any business action IDs that are not in the current
 * content. If so, it fetches and returns the missing pieces
 * @param currentContent
 * @param plan
 */
export function getMissingPosts(currentContent, plan) {}
