import React from 'react';
import {
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  PolarAngleAxis,
} from 'recharts';

function DoublePotentialChart(props) {
  const { prev, next } = props.data;
  const { fills } = props;

  const data = [
    { name: 'Anterior', value: prev, fill: fills.prev },
    { name: 'Siguiente', value: next, fill: fills.next },
  ];

  return (
    <ResponsiveContainer width="100%" height={240}>
      <RadialBarChart
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        startAngle={90}
        endAngle={-270}
        barSize={20}
        innerRadius="70%"
        outerRadius="130%"
      >
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          angleAxisId={0}
          tick={false}
        />
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          angleAxisId={1}
          tick={false}
        />
        <RadialBar
          dataKey={'value'}
          angleAxisId={0}
          data={[data[0]]}
          background
        />
        <RadialBar
          dataKey={'value'}
          angleAxisId={1}
          data={[data[1]]}
          background
        />
      </RadialBarChart>
    </ResponsiveContainer>
  );
}

export default DoublePotentialChart;
