import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { AUTH_USER_KEY } from '../constants';
import { setAuthUser } from '../state/actions/session';
import { FirebaseContext } from '../firebase';

async function extractAdditionalUserData(authUser) {
  try {
    const decodedToken = await authUser.getIdTokenResult();
    return {
      firstName:
        decodedToken?.claims?.firebase?.sign_in_attributes?.[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'
        ],
      isAdmin: decodedToken?.claims?.isAdmin,
    };
  } catch (err) {
    return undefined;
  }
}

function withAuthentication(Component) {
  function WithAuthentication(props) {
    const { handleSetAuthUser } = props;
    const firebase = useContext(FirebaseContext);

    // If user found in local storage, load it in Redux
    const fromLocal = JSON.parse(localStorage.getItem(AUTH_USER_KEY));
    handleSetAuthUser(fromLocal);

    useEffect(() => {
      // Define the callbacks for changes in Firebase auth
      firebase.onAuthListener(
        // If user has logged in extract first name and set in Redux/storage
        authUser => {
          localStorage.setItem(AUTH_USER_KEY, JSON.stringify(authUser));
          handleSetAuthUser(authUser);
        },
        // Else, if user has logged out, remove from Redux/storage
        () => {
          localStorage.removeItem(AUTH_USER_KEY);
          handleSetAuthUser(null);
        },
      );
    }, []);

    return <Component {...props} />;
  }

  const mapDispatchToProps = dispatch => ({
    handleSetAuthUser: authUser => dispatch(setAuthUser(authUser)),
  });

  return connect(
    null,
    mapDispatchToProps,
  )(WithAuthentication);
}

export default withAuthentication;
