import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles/index';
import Grid from '@material-ui/core/Grid/index';
import IconButton from '@material-ui/core/IconButton/index';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import TextField from '@material-ui/core/TextField/index';
import { changeAnimationGoodieQuantity } from '../../state/actions/business-plan';

const useStyles = makeStyles(() => ({
  quantityTextField: {
    maxWidth: 40,
  },
  quantityInput: {
    padding: 6,
    fontSize: '0.85rem',
  },
}));

const InputWithStepButtons = props => {
  const {
    goodieId,
    animationId,
    supportingActions,
    changeAnimationGoodieQuantity,
  } = props;

  const classes = useStyles();

  // This is bad; it's a patch due to how the modal content is poorly construed
  // On quantity change, the content of the modal is not re-rendered, therefore
  // the quantities in the selector do not change
  // Calls for a major refactoring
  const animation = supportingActions[animationId];
  const goodie = animation.goodies[goodieId];
  const quantity = goodie.quantity;

  function handleQuantityIncrease() {
    changeAnimationGoodieQuantity(animationId, goodieId, quantity + 1);
  }

  function handleQuantityDecrease() {
    const newQuantity = quantity === 0 ? 0 : quantity - 1;
    changeAnimationGoodieQuantity(animationId, goodieId, newQuantity);
  }

  // TODO: Add validation
  function handleQuantityChange(newQuantity) {
    const num = Number(newQuantity);
    if (isNaN(num)) {
      return;
    }
    changeAnimationGoodieQuantity(animationId, goodieId, num);
  }

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        <IconButton size="small" onClick={handleQuantityDecrease}>
          <RemoveCircleIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <TextField
          className={classes.quantityTextField}
          onChange={ev => handleQuantityChange(ev.target.value)}
          onBlur={ev => handleQuantityChange(ev.target.value)}
          value={quantity}
          variant="outlined"
          size="small"
          margin="none"
          inputProps={{ className: classes.quantityInput }}
        />
      </Grid>
      <Grid item>
        <IconButton size="small" onClick={handleQuantityIncrease}>
          <AddCircleIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  supportingActions: state.businessPlan.supportingActions,
});

const actionCreators = {
  changeAnimationGoodieQuantity,
};

const ConnectedInputWithStepButtons = connect(
  mapStateToProps,
  actionCreators,
)(InputWithStepButtons);

export default ConnectedInputWithStepButtons;
