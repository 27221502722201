import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import AppBar from './AppBar';
import Toolbar from './Toolbar';
import TopLogo from './TopLogo';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NavMenu from './NavMenu';
import CustomerSearchBox from '../CustomerSearchBox';

const useStyles = makeStyles(theme => ({
  hamburgerIcon: {
    marginLeft: theme.spacing(2),
  },
  whiteHamburger: {
    color: 'rgb(215, 215, 215)',
  },
}));

export default function TopBar({ displayWhiteLogo = false }) {
  const classes = useStyles();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function toggleMenu(newState) {
    setIsMenuOpen(newState);
  }

  return (
    <AppBar>
      <Toolbar>
        <TopLogo displayWhiteLogo={displayWhiteLogo} />
        <CustomerSearchBox />
        <IconButton
          className={clsx(
            classes.hamburgerIcon,
            displayWhiteLogo && classes.whiteHamburger,
          )}
          onClick={() => toggleMenu(true)}
        >
          <MenuIcon />
        </IconButton>
        <NavMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      </Toolbar>
    </AppBar>
  );
}
