import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import firebase from '../../firebase';

const useStyles = makeStyles(theme => ({
  modalInnerContainer: {
    width: 860,
    padding: theme.spacing(3),
    position: 'absolute',
    top: '33%',
    left: '50%',
    transform: 'translate(-50%, -33%)',
  },
}));

const exampleSheetUrl =
  'https://docs.google.com/spreadsheets/d/1UkOiA2tDBQjXgU45aOe-_RMlMQ-HeaO3OFuvguUbxXU/edit?usp=sharing';

export default function CustomerCsvUploadModal({
  isOpen,
  handleFileUpload,
  handleClose,
}) {
  const classes = useStyles();

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Paper className={classes.modalInnerContainer} square>
        <Typography variant="h3" gutterBottom>
          Subida de CSV de clientes
        </Typography>
        <Typography variant="h5" gutterBottom>
          Instrucciones
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>El fichero debe ser en formato .csv</li>
            <li>
              Debe contar con las cabeceras y columnas:{' '}
              <ul>
                <li>
                  <em>id: </em> referencia interna de L'Oréal
                </li>
                <li>
                  <em>companyName: </em> nombre del cliente (visible en la app)
                </li>
                <li>
                  <em>repEmail: </em> el email del comercial (BC) responsable
                </li>
                <li>
                  <em>brandScopes: </em> las organización comercial (marcas de
                  la DPP) de la que depende el cliente
                </li>
              </ul>
            </li>
            <li>
              Los posibles valor de marcas son:{' '}
              <em>lp, kerastase, shu, matrix, redken</em>
            </li>
            <li>
              La lista de marcas debe figurar separada por puntos y comas, sin
              espacios, tal como aparecen en el CSV de ejemplo
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom>
          El sistema rechazará los ficheros que no cumplan estas características
          y no subirá los datos.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Puedes ver un CSV de ejemplo a continuación:
        </Typography>
        <Box mb={3}>
          <Link href={exampleSheetUrl} target="_blank" rel="noopener">
            Ver CSV de ejemplo
          </Link>
        </Box>
        <Box mt={4} mb={2}>
          <Divider />
        </Box>
        <Grid container justify="space-between">
          <Grid item>
            <Button onClick={handleClose}>Volver</Button>
          </Grid>
          <Grid item>
            <input
              id="file-upload-input"
              type="file"
              accept=".csv"
              hidden
              onChange={handleFileUpload}
            />
            <label htmlFor="file-upload-input">
              <Button
                variant="contained"
                color="secondary"
                component="span"
                disableElevation
                endIcon={<CloudUploadIcon />}
              >
                Subir CSV
              </Button>
            </label>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}

CustomerCsvUploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
