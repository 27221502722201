// TODO: Should be getting only the new ones, with old ones optionally
import { toast } from 'react-toastify/dist/index';
import { content } from '../../services/content/api';
import { changeSupportingActionFilterUi, showBackdropWithSpinner } from './ui';
import { SUPPORTING_ACTION_FILTERS_LOCALSTORAGE_KEY } from '../../constants';

export const LOAD_SUPPORTING_ACTIONS = 'LOAD_SUPPORTING_ACTIONS';
export const APPEND_SUPPORTING_ACTIONS = 'APPEND_SUPPORTING_ACTIONS';
export const REMOVE_SUPPORTING_ACTIONS = 'REMOVE_SUPPORTING_ACTIONS';

export function loadSupportingActions(supportingActions) {
  return { type: LOAD_SUPPORTING_ACTIONS, payload: supportingActions };
}

export function appendSupportingActions(supportingActions) {
  return { type: APPEND_SUPPORTING_ACTIONS, payload: supportingActions };
}

export function removeSupportingActions(brand, productTypesToKeep) {
  return {
    type: REMOVE_SUPPORTING_ACTIONS,
    payload: { brand, productTypesToKeep },
  };
}

export function fetchAndLoadSupportingActions(fetchOnlyCurrent) {
  return async function(dispatch, getState) {
    try {
      dispatch(showBackdropWithSpinner(true));
      const { supportingActionFilters: filters } = getState().ui;
      const supportingActionsMap = await content.getSupportingActionsWithFilters(
        filters,
        fetchOnlyCurrent,
      );
      dispatch(loadSupportingActions(supportingActionsMap));
    } catch (err) {
      console.error('fetchAndLoadSupportingActions error: ', err);
      toast.error(
        'No se pudo cargar el contenido. Por favor inténtalo de nuevo.',
      );
      // TODO: Attempt toast.success // toast does not seem to be rendering
    } finally {
      dispatch(showBackdropWithSpinner(false));
    }
  };
}

export function changeSupportingActionSelection(brand, productType, shouldAdd) {
  return async function(dispatch, getState) {
    try {
      dispatch(showBackdropWithSpinner(true));
      // TODO: Fix convoluted supporting action filter logic
      // This logic is very convoluted; in particular the separation
      // between adding / removing
      // This is caused by a poor mapping of filters actions + reducers
      // to supporting actions... The design needs to be improved from the ground up
      if (shouldAdd) {
        const mapOfSupportingActionsToAdd = await content.getSupportingActionsByBrandAndProductType(
          brand,
          productType,
        );
        dispatch(appendSupportingActions(mapOfSupportingActionsToAdd));
      }
      dispatch(changeSupportingActionFilterUi(brand, productType, shouldAdd));
      const newFilters = getState().ui.supportingActionFilters;
      localStorage.setItem(
        SUPPORTING_ACTION_FILTERS_LOCALSTORAGE_KEY,
        JSON.stringify(newFilters),
      );
      if (!shouldAdd) {
        const brandFilters = newFilters[brand];
        const toKeep = Object.keys(brandFilters).filter(
          productTypeKey => brandFilters[productTypeKey] === true,
        );
        dispatch(removeSupportingActions(brand, toKeep));
      }
    } catch (err) {
      console.error('changeSupportingActionSelections error: ', err);
      toast.error(
        'Hubo un error al cargar el contenido, por favor inténtalo de nuevo',
      );
    } finally {
      dispatch(showBackdropWithSpinner(false));
    }
  };
}
