import React from 'react';
import Grid from '@material-ui/core/Grid';
import PaperHeaderContainer from './PaperHeaderContainer';
import PotentialChartTabContainer from './PotentialChartTabContainer';
import SellOutTableContainer from './SellOutTableContainer';
import SubViewMainContentArea from './SubViewMainContentArea';
import Box from '@material-ui/core/Box';
import QualitativeObjectives from './QualitativeObjectives';

export default function BusinessModelView(props) {
  return (
    <SubViewMainContentArea>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <PaperHeaderContainer title="Marca tus objetivos" isFlat>
            <SellOutTableContainer isEditable />
            <Box mt={4}>
              <QualitativeObjectives />
            </Box>
          </PaperHeaderContainer>
        </Grid>
        <Grid item xs={3}>
          <PaperHeaderContainer title="Tus proyecciones" isFlat>
            <PotentialChartTabContainer />
          </PaperHeaderContainer>
        </Grid>
      </Grid>
    </SubViewMainContentArea>
  );
}
