export function generateColumns(numCollabs, salesPeriod) {
  function calculatePeriodSales(salesWeekCollab) {
    return salesWeekCollab * numCollabs * salesPeriod;
  }

  return [
    { title: 'Producto', name: 'product' },
    { title: 'Tipo', name: 'type' },
    {
      title: 'Ventas (Objetivo)',
      name: 'sellOut',
      getCellValue: (row) => {
        const val = calculatePeriodSales(row.salesWeekCollab);
        return isNaN(val) ? 'N/A' : val;
      },
    },
    {
      title: 'Ventas (Reales)',
      name: 'realSales',
    },
    // {
    //   title: '% logrado',
    //   name: 'attained',
    // },
  ];
}

export const defaultColumnExtensions = [
  {
    columnName: 'product',
    width: 120,
  },
  {
    columnName: 'sellOut',
    align: 'right',
  },
  {
    columnName: 'realSales',
    align: 'right',
  },
];
