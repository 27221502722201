import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import FilterIcon from '@material-ui/icons/TuneRounded';
import { ACTION_TYPE_NAMES } from '../../constants';
import { fetchAndLoadSupportingActions } from '../../state/actions/supporting-actions';
import PaperHeaderContainer from '../PaperHeaderContainer';
import SupportingActionList from './SupportingActionList';
import Fab from '@material-ui/core/Fab';
import SubViewMainContentArea from '../SubViewMainContentArea';
import SupportingActionsFilterDrawer from './SupportingActionsFilterDrawer';

export const FILTER_DRAWER_WIDTH = 300;

const useStyles = makeStyles(theme => ({
  rootContainer: {
    position: 'relative',
    display: 'flex',
  },
  fab: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: theme.palette.text.primary,
    // SubViewMainContentArea has padding-top 4; align
    top: theme.spacing(4),
    left: '35px',
  },
  gridContainer: {
    '& > .MuiGrid-item': {
      padding: theme.spacing(0, 3),
    },
  },
  subViewMainContainer: {
    flexGrow: 1,
  },
}));

const SupportingActionView = props => {
  const classes = useStyles();

  const { supportingActions, fetchAndLoadSupportingActions } = props;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  if (!supportingActions) {
    fetchAndLoadSupportingActions(true);
  }

  const supportingActionList = supportingActions
    ? Object.values(supportingActions)
    : [];

  const animations = supportingActionList.filter(
    item => item.actionType === ACTION_TYPE_NAMES.animation,
  );

  const coachings = supportingActionList.filter(
    item => item.actionType === ACTION_TYPE_NAMES.coaching,
  );

  return (
    <div className={classes.rootContainer}>
      <Fab
        className={classes.fab}
        size="small"
        onClick={() => setIsDrawerOpen(true)}
      >
        <FilterIcon />
      </Fab>
      <SupportingActionsFilterDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
      />
      <SubViewMainContentArea classes={classes.subViewMainContainer}>
        <Grid className={classes.gridContainer} container>
          <Grid item xs={12} md={6}>
            <PaperHeaderContainer title="Catálogo de tendencias" isFlat>
              <SupportingActionList list={animations} isEditable />
            </PaperHeaderContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <PaperHeaderContainer title="Catálogo de imprescindibles" isFlat>
              <SupportingActionList list={coachings} isEditable />
            </PaperHeaderContainer>
          </Grid>
        </Grid>
      </SubViewMainContentArea>
    </div>
  );
};

const mapStateToProps = state => ({
  supportingActions: state.businessPlan.supportingActions,
});

const actionCreators = {
  fetchAndLoadSupportingActions,
};

const ConnectedSupportingActionListView = connect(
  mapStateToProps,
  actionCreators,
)(SupportingActionView);

export default ConnectedSupportingActionListView;
