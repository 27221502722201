import { connect } from 'react-redux';
import BusinessBasicDataFrom from '../components/BusinessBasicDataForm';
import {
  setNumCollabs,
  setPrevSalesPeriod,
  setPrevRetailSales,
  setPrevServiceSales,
  setPrevColoringSales,
  setIsColoringActive,
} from '../state/actions/business-plan';

function mapStateToProps(state) {
  const {
    prevSalesPeriod,
    prevServiceSales,
    prevRetailSales,
    prevColoringSales,
    numCollabs,
    isColoringActive,
  } = state.businessPlan;
  return {
    numCollabs,
    prevSalesPeriod,
    prevServiceSales,
    prevColoringSales,
    prevRetailSales,
    isColoringActive,
  };
}

// TODO: Use shorthand
function mapDispatchToProps(dispatch) {
  return {
    onNumCollabsChange: val => dispatch(setNumCollabs(val)),
    onPrevSalesPeriodChange: val => dispatch(setPrevSalesPeriod(val)),
    onPrevServiceSalesChange: val => dispatch(setPrevServiceSales(val)),
    onPrevRetailSalesChange: val => dispatch(setPrevRetailSales(val)),
    onPrevColoringSalesChange: val => dispatch(setPrevColoringSales(val)),
    setIsColoringActive: val => dispatch(setIsColoringActive(val)),
  };
}

const ConnectedBasicBusinessDataForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BusinessBasicDataFrom);

export default ConnectedBasicBusinessDataForm;
