/**
 * Deeply checks whether two objects have the same keys, and same value types
 * Returns false if objects have different shapes
 * Returns true if objects have same shape, but different deep primitives
 *
 * @example
 * // returns false
 * const a = { 'a': 1, 'b': 2 }
 * const b = { 'a': 2, 'c': 3 }
 * objectsHaveSameDeepKeysAndValueTypes(a, b)
 *
 * @example
 * // returns false
 * const a = { 'a': 3, 'b': 3 }
 * const b = { 'a': 2, 'b': 4 }
 * objectsHaveSameDeepKeysAndValueTypes(a, b)
 *
 *
 * @param {object} a - any object
 * @param {object}  b - any object
 * @returns {boolean}
 */
export function objectsHaveSameDeepKeysAndValueTypes(a, b) {
  if (typeof a !== typeof b) {
    return false;
  }
  // Bottom out of recursion; we have found non-object primitives of same type
  if (typeof a !== 'object' || typeof b !== 'object') {
    return true;
  }
  if (Object.keys(a).length !== Object.keys(b).length) {
    return false;
  }

  for (let [aKey, aValue] of Object.entries(a)) {
    const bValue = b[aKey];
    if (!bValue || !objectsHaveSameDeepKeysAndValueTypes(aValue, bValue)) {
      return false;
    }
  }

  return true;
}
