import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { BRAND_LOGO_MAP } from '../../constants';
import { changeSupportingActionSelection } from '../../state/actions/supporting-actions';

const useStyles = makeStyles(theme => ({
  listRoot: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0.5),
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4),
  },
  logoContainer: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0, 0),
    minHeight: '50px',
  },
  logo: {
    maxHeight: '50px',
    maxWidth: '90px',
  },
}));

// This is a patch; changes 'Servicio' to 'Tratamiento'
function getProductTypeDisplayName(productType) {
  if (productType === 'Servicio') {
    return 'Tratamiento';
  }
  return productType;
}

export function SupportingActionsFilterList({
  filters,
  changeSupportingActionSelection,
}) {
  const classes = useStyles();

  const brandNames = Object.keys(filters);
  const [brandsOpen, setBrandsOpen] = useState(
    brandNames.reduce((ac, val) => ({ ...ac, [val]: false }), {}),
  );

  function handleBrandClick(brand) {
    setBrandsOpen({ ...brandsOpen, [brand]: !brandsOpen[brand] });
  }

  return (
    <List className={classes.listRoot}>
      {brandNames.map(brand => (
        <>
          <ListItem
            key={brand}
            dense
            button
            onClick={() => handleBrandClick(brand)}
          >
            {BRAND_LOGO_MAP[brand] ? (
              <div className={classes.logoContainer}>
                <img
                  className={classes.logo}
                  src={BRAND_LOGO_MAP[brand]}
                  alt={`logo-${brand}`}
                />
              </div>
            ) : (
              <ListItemText primary={brand} />
            )}
            <IconButton
              size="small"
              disableRipple
              onClick={() => handleBrandClick(brand)}
            >
              {brandsOpen[brand] ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItem>
          <Collapse in={brandsOpen[brand]} timeout="auto" unmountOnExit>
            <List component="div" dense disablePadding>
              {Object.keys(filters[brand]).map(productType => (
                <ListItem
                  className={classes.nestedListItem}
                  key={`${brand}-${productType}`}
                >
                  <Checkbox
                    size="small"
                    checked={filters[brand][productType] === true}
                    onChange={ev =>
                      changeSupportingActionSelection(
                        brand,
                        productType,
                        ev.target.checked,
                      )
                    }
                  />
                  {getProductTypeDisplayName(productType)}
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      ))}
    </List>
  );
}

function mapStateToProps(state) {
  return {
    filters: state.ui.supportingActionFilters,
  };
}

const mapDispatchToProps = {
  changeSupportingActionSelection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupportingActionsFilterList);
